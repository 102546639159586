import { useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import UserDeleteModal from './user-delete-modal'
import { FaChevronRight, FaChevronDown } from 'react-icons/fa'

import styles from './user-table.module.scss'
import UserDetailTable from './user-detail-table'

const columns = [{
  dataField: 'firstname',
  text: 'Firstname',
  sort: true,
  sortCaret: (order, column) => {
    if (!order) return (<span>&nbsp;&nbsp;▲/▼</span>)
    else if (order === 'asc') return (<span>&nbsp;&nbsp;▼/<font color="blue">▲</font></span>)
    else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="blue">▼</font>/▲</span>)
    return null;
  }
}, {
  dataField: 'lastname',
  text: 'Lastname'
}, {
  dataField: 'email',
  text: 'Email'
}, {
  dataField: 'tickets',
  text: 'Tickets',
  sort: true,
  sortCaret: (order, column) => {
    if (!order) return (<span>&nbsp;&nbsp;▲/▼</span>)
    else if (order === 'asc') return (<span>&nbsp;&nbsp;▼/<font color="blue">▲</font></span>)
    else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="blue">▼</font>/▲</span>)
    return null;
  }
}, {
  dataField: 'roleName',
  text: 'Role',
  sort: true,
  sortCaret: (order, column) => {
    if (!order) return (<span>&nbsp;&nbsp;▲/▼</span>)
    else if (order === 'asc') return (<span>&nbsp;&nbsp;▼/<font color="blue">▲</font></span>)
    else if (order === 'desc') return (<span>&nbsp;&nbsp;<font color="blue">▼</font>/▲</span>)
    return null;
  }
}]

function UserTable ({ users, roles, ticketAdd, ticketRemove, selectedUser, setSelected, changeUserRole, resendActivation, deleteUser }) {
  const [userToDelete, setUserToDelete] = useState({})
  const [showUserDeleteModal, setShowUserDeleteModal] = useState(false)

  const expandRow = {
    onExpand: (row, isExpand, rowIndex, e) => {
      if (isExpand) {
        setSelected(row.userID)
      } else {
        setSelected(0)
      }
    },
    expanded: [selectedUser],
    onlyOneExpanding: true,
    showExpandColumn: true,
    expandHeaderColumnRenderer: () => {
      return null
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <FaChevronDown />
        );
      }
      return (
        <FaChevronRight />
      );
    },
    renderer: (user) => {
      const { userID } = user
      return (
        <div key={userID} className={styles.userTableBody}>
          <UserDetailTable 
            user={user}
            resendActivation={resendActivation}
            changeUserRole={changeUserRole}
            roles={roles}
            ticketAdd={ticketAdd}
            ticketRemove={ticketRemove}
            setUserToDelete={setUserToDelete}
            setShowUserDeleteModal={setShowUserDeleteModal}
          />
          <UserDeleteModal 
            handleClose={() => setShowUserDeleteModal(false)} 
            user={userToDelete} 
            show={showUserDeleteModal}
            deleteUser={() => {
              setShowUserDeleteModal(false)
              deleteUser(userToDelete.userID)
            }}
          />
        </div>
      )
  
    }
  };
  
  let parsedUsers = users.map(u => {
    const userRole = roles.find(r => r.roleID === u.roleID)
    return {
      ...u,
      roleName: userRole.name,
    }
  })

  const rowStyle = { cursor: 'pointer' }

  return (
    <BootstrapTable
      keyField='userID'
      data={ parsedUsers }
      columns={ columns }
      pagination={ paginationFactory() }
      expandRow={ expandRow }
      rowStyle={ rowStyle }
      bordered = { false}
    />
  )
}


  
export default UserTable
