import { Modal, Button } from 'react-bootstrap'

function calendarDeleteModal({ show, handleClose, deleteCalendar, calendar }) {
  const { name } = calendar

  return (
    <>
      <Modal 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered 
        show={show} 
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to delete these openinghours?</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <span>Openinghours: {name}</span>
          <br/>
          <span><b>Warning:</b> Deleting openinghours can cause issues if users have booking for this period.</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="outline-danger" onClick={deleteCalendar}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default calendarDeleteModal