import styles from './banner.module.scss'
import config from '../config'

const {
  REACT_APP_BACKEND_URL 
} = config

function Banner () {
  return (
    <div className={styles.banner}>
      <img
        alt="banner"
        src={`${REACT_APP_BACKEND_URL}/branding/images/banner.png`}
      />
    </div>
  )
}

export default Banner