import { Modal, Button } from 'react-bootstrap'

function UserDeleteModal({ show, handleClose, deleteUser, user }) {
  const { firstname, lastname } = user

  return (
    <>
      <Modal 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered 
        show={show} 
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to delete this user?</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <span>User: {firstname} {lastname}</span>
          <br/>
          <span><b>Warning:</b> deleting a user will also delete all bookings to it.</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="outline-danger" onClick={deleteUser}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UserDeleteModal