import { connect } from 'react-redux'

function mapStateToProps (state) {
  return {
    organisation: state.organisation.data || {}
  }
}

function Calendar ({ organisation }) {
  const src = `https://calendar.google.com/calendar/embed?src=${organisation.googleCalendarURL}&ctz=Europe%2FBrussels&mode=AGENDA`
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <iframe
        title="calendar"
        src={src} 
        width="100%" 
        height="100%" 
        frameBorder="0" 
        scrolling="no"
      />
    </div>
  )
}

export default connect(
  mapStateToProps,
  null
)(Calendar)