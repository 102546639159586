import { useState } from 'react'
import { Table } from 'react-bootstrap'
import { BsPlusCircleFill } from 'react-icons/bs'
import { RiDeleteBinLine, RiEditBoxFill } from 'react-icons/ri'

import TicketPriceCreateModal from './ticket-price-create-modal'
import TicketPriceDeleteModal from './ticket-price-delete-modal'

import styles from './index.module.scss'
import { find } from 'lodash'


function TicketPrices ({ roles, ticketPrices, createTicketPrice, deleteTicketPrice, updateTicketPrice }) {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [toEdit, setToEdit] = useState(undefined)

  const [toDelete, setToDelete] = useState(undefined)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  return (
    <>
      <div className={styles.tableHeader}>
        <h2>Ticket Prices</h2>
        <BsPlusCircleFill onClick={() => setShowCreateModal(true)}/>
      </div>
      <Table borderless={true} striped={true}>
        <thead>
          <tr>
            <th>Ticket price name</th>
            <th>Ticket amount</th>
            <th>Ticket price</th>
            <th>Role link</th>
          </tr>
        </thead>
        <tbody>
          {ticketPrices.map(ticketPrice => {
            const role = find(roles, r => ticketPrice.roleID === r.roleID)
            return (
              <tr key={ticketPrice.ticketPriceID}>
                <td>{ticketPrice.name}</td>
                <td>{ticketPrice.ticketAmount}</td>
                <td>€ {ticketPrice.price}</td>
                <td>{role.name}</td>
                <td>
                  <RiDeleteBinLine
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setToDelete(ticketPrice)
                      setShowDeleteModal(true)
                    }}
                  />
                  <RiEditBoxFill
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setToEdit(ticketPrice)
                      setShowEditModal(true)
                    }}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>

      {roles.length > 0 && (
        <TicketPriceCreateModal 
          show={showCreateModal} 
          roles={roles}
          create={(values) => {
            setShowCreateModal(false)
            createTicketPrice(values)
          }}
          handleClose={() => setShowCreateModal(false)} 
        />
      )}

      {toDelete && (
        <TicketPriceDeleteModal 
          show={showDeleteModal} 
          ticketPrice={toDelete}
          deleteTicketPrice={() => {
            setShowDeleteModal(false)
            deleteTicketPrice(toDelete.ticketPriceID)
          }}
          handleClose={() => setShowDeleteModal(false)} 
        />
      )}

      {toEdit && (
        <TicketPriceCreateModal 
          show={showEditModal}
          roles={roles}
          ticketPrice={toEdit}
          create={(changedPrice) => {
            setShowEditModal(false)
            updateTicketPrice(changedPrice)
          }}
          handleClose={() => setShowEditModal(false)} 
        />
      )}
    </>
  )
}

export default TicketPrices
