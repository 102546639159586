import { Modal, Button, Form } from 'react-bootstrap'
import { map } from 'lodash'
import { useFormik } from 'formik'

import styles from './ticket-price-create-modal.module.scss'

const validate = values => {
  const errors = {}

  if (!values.name) {
    errors.name = 'Required'
  }

  if (values.ticketAmount <= 0) {
    errors.ticketAmount = 'Ticket amount must be greater than 0'
  }

  if (values.price <= 0) {
    errors.price = 'Ticket price must be greater than 0'
  }

  return errors
}

function TicketPriceCreateModal({ show, handleClose, roles, create, ticketPrice }) {
  const toEdit = !!ticketPrice

  const defaults = {
    name: '',
    ticketAmount: 1,
    price: 0,
    roleID: roles[0].roleID
  }

  const formik = useFormik({
    initialValues: toEdit ? ticketPrice : defaults,
    validate,
    onSubmit: values => {
      values.roleID = parseInt(values.roleID)
      console.log(values)
      create(values)
    },
  })

  return (
    <>
      <Modal 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered 
        show={show} 
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          {toEdit ? (
            <Modal.Title>Edit Ticket Price</Modal.Title>
          ) : (
            <Modal.Title>Create Ticket Price</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group>
              <Form.Label>Ticket Price Name</Form.Label>
              <Form.Control
                placeholder="Name"
                required
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <Form.Text className="text-muted">
                This ticket price name will be displayed in the user side of the application
              </Form.Text>
              {formik.touched.name && formik.errors.name ? (
                <div className={styles.errorField}>{formik.errors.name}</div>
              ) : null}
            </Form.Group>
            
            <RolesSelect
              roles={roles}
              onChange={formik.handleChange}
            />

            <Form.Group>
              <Form.Label>Number of tickets</Form.Label>
              <Form.Control
                placeholder="number of tickets"
                required
                name="ticketAmount"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.ticketAmount}
                type='number'
              />
              <Form.Text className="text-muted">
                Number of tickets that a user will get for this price category
              </Form.Text>
              {formik.touched.ticketAmount && formik.errors.ticketAmount ? (
                <div className={styles.errorField}>{formik.errors.ticketAmount}</div>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label>Ticket price</Form.Label>
              <Form.Control
                placeholder="Name"
                required
                name="price"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.price}
                type='number'
              />
              <Form.Text className="text-muted">
                Price for this price category
              </Form.Text>
              {formik.touched.price && formik.errors.price ? (
                <div className={styles.errorField}>{formik.errors.price}</div>
              ) : null}
            </Form.Group>

            <div className={styles.buttons}>
              <Button variant="outline-secondary" onClick={handleClose}>
                Close
              </Button>
              {toEdit ? (
                <Button className={styles.confirmButton} type='submit'>
                  Edit
                </Button>
              ) : (
                <Button className={styles.confirmButton} type='submit'>
                  Create
                </Button> 
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

const RolesSelect = ({ roles, onChange }) => {
  return (
    <div className={styles.resourceSelect}>
      <span>Select a role</span>
      <Form.Control as="select" onChange={onChange} name='roleID'>
        {
          map(roles, role => {
            const { name, roleID } = role
            return (
              <option key={roleID} value={roleID}>
                {name}
              </option>
            )
          })
        }
      </Form.Control>
    </div>
  )
}

export default TicketPriceCreateModal
