import { useEffect, useState } from 'react'
import { useAsync } from 'react-async'
import { Table } from 'react-bootstrap'
import { RiDeleteBinLine, RiEditBoxFill } from 'react-icons/ri'
import { actions as notificationActions } from '../../../redux/notification'
import { connect } from 'react-redux'

import { getCalendars, createCalendar, deleteCalendar, updateCalendar } from '../../../lib/api'

import EditCalendarModal from './editCalendarModal'
import NewCalendar from './newCalendar'
import CalendarDeleteModal from './calendarDeleteModal'
import Loader from '../../../components/loader'

import styles from './index.module.scss'

function mapDispatchToProps (dispatch) {
  return {
    addNotification: (text, options) => dispatch(notificationActions.addNotification(text, options))
  }
}

const constraintsFetcher = () => getCalendars()

function OpeningHours ({ addNotification }) {
  const [openEditModal, setOpenEditModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [selectedCalendar, setSelectedCalendar] = useState()

  const { data: calendars = {}, isPending: calendarsLoading, run: getCalendars } = useAsync({
    deferFn: constraintsFetcher
  })

  useEffect(() => {
    getCalendars()
  }, [getCalendars])

  const handleCreateCalendar = async (calendar) => {
    await createCalendar(calendar)
    addNotification('Openinghours created!')
    getCalendars()
  }

  const handleDeleteCalendar = async () => {
    setOpenDeleteModal(false)
    await deleteCalendar(selectedCalendar.calendarID)
    addNotification('Openinghours deleted!')
    getCalendars()
  }

  const handleUpdateCalendar = async (calendar) => {
    const { calendarID, name, constraints } = calendar
    await updateCalendar(calendarID, { name, constraints })
    getCalendars()
    setOpenEditModal(false)
    addNotification('Openinghours edited!')
  }

  if (calendarsLoading) return <Loader />

  return (
    <div className={styles.container}>
      <h2>Manage openinghours</h2>

      {calendars.length === 0 && (
        <h4>No openingshours yet, add one now</h4>
      )}

      {calendars.length > 0 && (
        <Table borderless={true} striped={true}>
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {calendars.map(cal => {
              const { name } = cal
              return (
                <tr key={name}>
                  <td>{name}</td>

                  <td>
                    <RiEditBoxFill 
                      style={{ cursor: 'pointer', fontSize: '24px' }}
                      onClick={() => {
                        setOpenEditModal(true)
                        setSelectedCalendar(cal)
                      }}
                    />
                    <RiDeleteBinLine
                      style={{ cursor: 'pointer', fontSize: '24px' }}
                      onClick={() => {
                        setSelectedCalendar(cal)
                        setOpenDeleteModal(true)
                      }}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      )}

      <NewCalendar createCalendar={handleCreateCalendar}/>

      {openEditModal && selectedCalendar && (
        <EditCalendarModal
          selectedCalendar={selectedCalendar}
          show={openEditModal}
          handleClose={() => {
            setOpenEditModal(false)
            setSelectedCalendar(undefined)
          }}
          updateCalendar={handleUpdateCalendar}
        />
      )}

      {openDeleteModal && (
        <CalendarDeleteModal
          show={openDeleteModal}
          handleClose={() => {
            setOpenDeleteModal(false)
          }}
          calendar={selectedCalendar}
          deleteCalendar={handleDeleteCalendar}
        />
      )}

    </div>
  )
}

export default connect(
  null,
  mapDispatchToProps
)(OpeningHours)