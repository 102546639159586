import { useState } from 'react'
import { Table } from 'react-bootstrap'
import { BsPlusCircleFill } from 'react-icons/bs'
import { RiDeleteBinLine, RiEditBoxFill } from 'react-icons/ri'

import RolePriceCreateModal from './role-price-create-modal'
import RolePriceDeleteModal from './role-price-delete-modal'

import styles from './index.module.scss'

function RolePrices ({ rolePrices, roles, createRolePrice, deleteRolePrice, updateRolePrice }) {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [toEdit, setToEdit] = useState(undefined)
  const [toDelete, setToDelete] = useState(undefined)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  return (
    <>
      <div className={styles.tableHeader}>
        <h2>Role Prices</h2>
        <BsPlusCircleFill onClick={() => setShowCreateModal(true)}/>
      </div>
      <Table hover borderless={true} striped={true}>
        <thead>
          <tr>
            <th>Role price name</th>
            <th>Role link</th>
            <th>Role price</th>
            <th>Duration in days</th>
          </tr>
        </thead>
        <tbody>
          {rolePrices.map(rolePrice => {
            const role = roles.find(role => role.roleID === rolePrice.roleID)

            return (
              <tr key={rolePrice.rolePriceID}>
                <td>{rolePrice.name}</td>
                <td>{role.name}</td>
                <td>€ {rolePrice.price}</td>
                <td>{rolePrice.duration}</td>
                <td>
                  <RiDeleteBinLine
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setToDelete(rolePrice)
                      setShowDeleteModal(true)
                    }}
                  />
                  <RiEditBoxFill
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setToEdit(rolePrice)
                      setShowEditModal(true)
                    }}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>

      {roles.length > 0 && (
        <RolePriceCreateModal 
          show={showCreateModal} 
          roles={roles}
          create={(values) => {
            setShowCreateModal(false)
            createRolePrice(values)
          }}
          handleClose={() => setShowCreateModal(false)} 
        />
      )}

      {toDelete && (
        <RolePriceDeleteModal 
          show={showDeleteModal} 
          role={toDelete}
          deleteRolePrice={() => {
            setShowDeleteModal(false)
            deleteRolePrice(toDelete.rolePriceID)
          }}
          handleClose={() => setShowDeleteModal(false)} 
        />
      )}

      {roles.length > 0 && toEdit && (
        <RolePriceCreateModal 
          show={showEditModal} 
          roles={roles}
          create={(values) => {
            setShowEditModal(false)
            updateRolePrice(values)
          }}
          handleClose={() => setShowEditModal(false)}
          rolePrice={toEdit}
        />
      )}
    </>
  )
}

export default RolePrices
