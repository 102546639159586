import { useEffect } from "react"

function useInterval (action, period) {
  useEffect(() => {
    action()
    const interval = setInterval(action, period)
    return () => {
      clearInterval(interval)
    }
  }, [action, period])
}

export default useInterval