import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect, Router, Switch, Route, useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

import Navbar from '../components/navbar'
import Unknown from '../pages/unknown'
import Profile from '../pages/profile'
import Tickets from '../pages/tickets'
import Reservations from '../pages/reservations'
import Admin from '../pages/admin'
import Calendar from '../pages/googleCalendar'

import Loader from '../components/loader'

import { checkLoginState, logout } from '../lib/auth'
import { initStore } from '../redux/store'

import styles from './main.module.scss'

function mapStateToProps (state) {
  return {
    user: state.user,
    organisation: state.organisation
  }
}

function Main ({ user, organisation }) {
  useEffect(() => {
    initStore()
  }, [])

  const history = useHistory()

  const { loading: userLoading, data: userData, error: userError } = user
  const { loading: orgLoading, data: orgData, error: orgError } = organisation

  if (!checkLoginState() || !isEmpty(userError) || !isEmpty(orgError)) {
    logout(history)
    return <Redirect to='/login'/>
  }

  if (isEmpty(userData) || userLoading || (orgLoading && isEmpty(orgData))) return <Loader />

  return (
    <div className={styles.wrapper}>
      <Router history={history}>
        <Navbar />
        <div className={styles.page}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/reservations/new" />
            </Route>
            <Route path="/reservations">
              <Reservations />
            </Route>
            {orgData.googleCalendarURL && (
              <Route path="/calendar">
                <Calendar />
              </Route>
            )}
            <Route path="/tickets">
              <Tickets />
            </Route>
            <Route exact path="/profile">
              <Profile />
            </Route>
            <Route path="/admin">
              <Admin />
            </Route>
            <Route path='*'>
              <Unknown />
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  )
}

export default connect(
  mapStateToProps
)(Main)