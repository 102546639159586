import { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import moment from 'moment'
import { map } from 'lodash'
import { AiOutlineClockCircle, AiOutlineCalendar } from 'react-icons/ai'

import styles from './booking-create-modal.module.scss'

function BookingCreateModal({ handleClose, createBooking, event, users }) {
  const { end, start } = event

  const [selectedUserID, setSelectedUserID] = useState(users[0].userID)

  return (
    <>
      <Modal 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered 
        show={true} 
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create a booking</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <span><AiOutlineCalendar />Date: {moment(start).format('DD-MM')}</span>
          <span><AiOutlineClockCircle />{moment(start).format('HH:mm')} - {moment(end).format('HH:mm')}</span>
          <UserSelect users={users} selectedUserID={selectedUserID} handleChange={setSelectedUserID}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Close
          </Button>
          <Button disabled={!selectedUserID} variant="outline-primary" onClick={() => createBooking(selectedUserID)}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const UserSelect = ({ selectedUserID, users, handleChange }) => {
  return (
    <div className={styles.userSelect}>
      <span>Select a user</span>
      <Form.Control as="select" custom value={selectedUserID} onChange={(e) => handleChange(e.target.value)}>
        {
          map(users, user => {
            const { userID, firstname } = user
            return (
              <option key={userID} value={userID}>
                {firstname}
              </option>
            )
          })
        }
      </Form.Control>
    </div>
  )
}

export default BookingCreateModal