import { useState } from 'react'
import { Col, Row, Form, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { FaChevronLeft } from "react-icons/fa"

import { register } from '../lib/api'
import { actions as notificationActions } from '../redux/notification'

import styles from './register.module.scss'
import { omit } from 'lodash'

function mapDispatchToProps (dispatch) {
  return {
    addNotification: (text, options) => dispatch(notificationActions.addNotification(text, options))
  }
}

function Register ({ addNotification }) {
  const history = useHistory()
  const [success, setSuccess] = useState(false)

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      passwordCheck: '',
      birthdate: new Date()
    },
    validate,
    onSubmit: values => {
      register(omit(values, ['passwordCheck']))
        .then((res) => {
          console.log(res)
          addNotification('registered successfully')
          setSuccess(true)
        })
        .catch(err => {
          console.log(err)
        })
    },
  })

  if (success) {
    return (
      <div className={styles.success}>
        <h2>Registration success!</h2>
        <h4>Check your email in order to continue.</h4>
        <br/>
        <Button
          className={styles.goToLoginButton}
          variant="primary"
          onClick={() => history.push('/login')}
        >Take me to Login</Button>
      </div>
    )
  }

  return (
    <Row className={`${styles.row} align-items-center justify-content-center`} noGutters={true}>
      <Col className={styles.registerCol} xs={10} md={8} lg={8} xl={6}>
        <h2>Create your account</h2>
        <RegisterForm
          success={success}
          setSuccess={setSuccess}
          formik={formik}
          history={history}
        />
      </Col>
    </Row>
  )
}

const RegisterForm = ({ setSuccess, formik, history }) => {
  return (
    <Form noValidate onSubmit={formik.handleSubmit} className={styles.registerForm}>
      <Form.Group controlId="formEmail">
        <Form.Label className={styles.formLabel}>Email address</Form.Label>
        <Form.Control
          className={styles.formText}
          type="email"
          placeholder="Enter email"
          required
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.email}/>

        {formik.touched.email && formik.errors.email ? (
          <div className={styles.errorField}>{formik.errors.email}</div>
        ) : null}
      </Form.Group>

      <Form.Row>
          <Form.Group as={Col} md="6" controlId="formFirstname">
            <Form.Label className={styles.formLabel}>First name</Form.Label>
            <Form.Control
              className={styles.formText}
              placeholder="Firstname"
              required
              name="firstname"
              onChange={formik.handleChange}
              value={formik.values.firstname}
            />
            {formik.touched.firstname && formik.errors.firstname ? (
              <div className={styles.errorField}>{formik.errors.firstname}</div>
            ) : null}
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="formLastname">
            <Form.Label className={styles.formLabel}>Last name</Form.Label>
            <Form.Control
              className={styles.formText}
              placeholder="Lastname"
              required
              name="lastname"
              onChange={formik.handleChange}
              value={formik.values.lastname}/>
            {formik.touched.lastname && formik.errors.lastname ? (
              <div className={styles.errorField}>{formik.errors.lastname}</div>
            ) : null}
          </Form.Group>
      </Form.Row>

      <Form.Group controlId="formBirthdate">
        <Form.Label className={styles.formLabel}>Birthdate</Form.Label>
        <Form.Control
          className={styles.formText}
          type="date"
          placeholder="Birthdate"
          required
          name="birthdate"
          onChange={formik.handleChange}
          value={formik.values.birthdate}/>
        {formik.touched.birthdate && formik.errors.birthdate ? (
          <div className={styles.errorField}>{formik.errors.birthdate}</div>
        ) : null}
      </Form.Group>

      <Form.Group controlId="formPassword">
        <Form.Label className={styles.formLabel}>Password</Form.Label>
        <Form.Control
          className={styles.formText}
          type="password"
          placeholder="password"
          required
          name="password"
          onChange={formik.handleChange}
          value={formik.values.password}/>
        {formik.touched.password && formik.errors.password ? (
          <div className={styles.errorField}>{formik.errors.password}</div>
        ) : null}
      </Form.Group>

      <Form.Group controlId="formPasswordCheck">
        <Form.Label className={styles.formLabel}>Password Check</Form.Label>
        <Form.Control
          className={styles.formText}
          type="password"
          placeholder="password check"
          required
          name="passwordCheck"
          onChange={formik.handleChange}
          value={formik.values.passwordCheck}/>
        {formik.touched.passwordCheck && formik.errors.passwordCheck ? (
          <div className={styles.errorField}>{formik.errors.passwordCheck}</div>
        ) : null}
      </Form.Group>

      <Button className={styles.registerButton} variant="primary" type="submit">
        Sign Up
      </Button>
      <div className={styles.goBack} onClick={() => history.push('/login')}>
        <FaChevronLeft />
        <span>go back</span>
      </div>
    </Form>
  )
}

const validate = values => {
  const errors = {}

  if (!values.firstname) {
    errors.firstname = 'Required'
  } else if (values.firstname.length > 15) {
    errors.firstname = 'Must be 15 characters or less'
  }

  if (!values.lastname) {
    errors.lastname = 'Required'
  } else if (values.lastname.length > 20) {
    errors.lastname = 'Must be 20 characters or less'
  }

  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  if (!values.birthdate) {
    errors.birthdate = 'Required'
  } else if (new Date(values.birthdate) > new Date()) {
    errors.birthdate = 'Date cannot be in the future'
  }

  if (!values.password) {
    errors.password = 'Required'
  } else if (values.password.length < 6) {
    errors.password = 'Password must contain atleast 6 characters'
  }

  if (!values.passwordCheck) {
    errors.passwordCheck = 'Required'
  } else if (values.passwordCheck !== values.password) {
    errors.passwordCheck = 'password is not the same.'
  }


  return errors
}

export default connect(
  null,
  mapDispatchToProps
)(Register)