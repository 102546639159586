import { configureStore } from '@reduxjs/toolkit'

import { reducer as userReducer, actions as userActions } from './user'
import { reducer as notificationReducer } from './notification'
import { reducer as organisationReducer, actions as organisationActions } from './organisation'
import { checkLoginState } from '../lib/auth'

const store = configureStore({
  reducer: {
    user: userReducer,
    notification: notificationReducer,
    organisation: organisationReducer
  }
})

export function initStore () {
  if(!checkLoginState()) return
  store.dispatch(userActions.getProfile())
  store.dispatch(organisationActions.getInfo())
}

export default store