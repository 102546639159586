import { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import CreateConstraints from './createConstraints'
import ConstraintsTable from './constraintsTable'
import styles from './newCalendar.module.scss'

const NewCalendar = ({ createCalendar }) => {
  const [constraints, setConstraints] = useState([])
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState()

  const addConstraint = (newConstraint) => {
    setConstraints([ ...constraints, newConstraint ])
  }
  
  const handleDeleteConstraint = (index) => {
    const copy = [...constraints]
    copy.splice(index, 1)
    setConstraints(copy)
  }

  const handleCreateCalendar = () => {
    if (!name || name === '') {
      return setNameError('name is required')
    }
    createCalendar({
      name,
      constraints
    })
  }

  const handleNameChange = (name) => {
    setNameError('')
    setName(name)
  }

  return (
    <div className={styles.timeContainer}>
      <h4>Create new openinghours table</h4>
      <Form.Group className={styles.availabilityFormGroup} controlId={`name`}>
        <span>Name</span>
        <Form.Control
          type="text"
          placeholder="name"
          name='name'
          value={name}
          onChange={(e) => handleNameChange(e.target.value)}
          required
        />
        {nameError ? (
          <div className={styles.errorField}>{nameError}</div>
        ) : null}
      </Form.Group>

      <CreateConstraints createConstraint={(c) => addConstraint(c)}/>

      <ConstraintsTable constraints={constraints} deleteConstraint={handleDeleteConstraint} />
      
      {constraints.length > 0 && (
        <div className={styles.btnGroup}>
          <Button className={styles.savebtn} onClick={() => handleCreateCalendar()}>
            Save configuration
          </Button>
        </div>
      )}
    </div>
  )
}

export default NewCalendar