import { useState } from 'react'
import { Card, Col, Form, Row, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { login } from '../lib/api'
import { setToken } from '../lib/auth'
import { actions as notificationActions } from '../redux/notification'
import config from '../config'

import styles from './login.module.scss'

const {
  REACT_APP_BACKEND_URL
} = config

function mapDispatchToProps (dispatch) {
  return {
    addNotification: (text, options) => dispatch(notificationActions.addNotification(text, options))
  }
}

function Login ({ addNotification }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [validated, setValidated] = useState(false)

  const history = useHistory()

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    event.preventDefault()
    event.stopPropagation()

    setValidated(true)

    if (form.checkValidity() === true) {
      login(email, password)
        .then(({ token }) => {
          addNotification('logged in successfully')
          setToken(token)
          history.push('/')
        })
        .catch(err => {
          setPassword('')
        })
    }
  };

  return (
    <Row className={styles.loginRow} noGutters={true}>
      <Col className={styles.leftCol} xs={12} md={6} lg={6} xl={6}>
        <Card className={styles.card}>
          <Card.Body className={styles.cardBody}>
            <h2>Welcome back</h2>
            <h4>Welcome back! Please enter your details</h4>
            <Form className={styles.form} noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group controlId="formEmail">
                <Form.Label className={styles.formLabel}>Email</Form.Label>
                <Form.Control
                  className={styles.formText}
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}/>
                <Form.Control.Feedback type="invalid">
                  Gelieve een geldig email adres in te vullen.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formPassword">
                <Form.Label className={styles.formLabel}>Password</Form.Label>
                <Form.Control
                  className={styles.formText}
                  type="password"
                  placeholder="wachtwoord"
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}/>
                <Form.Control.Feedback type="invalid">
                  Gelieve een wachtwoord in te vullen.
                </Form.Control.Feedback>
              </Form.Group>
              <div className={styles.buttonGroup}>
                <Button className={styles.loginButton} variant="primary" type="submit">
                  Login
                </Button>
                <Button className={styles.button} variant="outline-secondary" onClick={() => history.push('/register')}>
                  Sign Up
                </Button>
              </div>
              <div className={styles.forgotpassword}>
                <span onClick={() => history.push('/reset-password')}>Forgot password?</span>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={0} md={0} lg={6} xl={6} className={styles.rightCol}>
        <img className={styles.img} alt='skischool' src={`${REACT_APP_BACKEND_URL}/branding/images/illustration-01.png`}/>
      </Col>
    </Row>
  )
}

export default connect(
  null,
  mapDispatchToProps
)(Login)