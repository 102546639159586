import { Redirect, Router, Switch, Route, useHistory } from 'react-router-dom'
import BookingsList from './bookings'
import AdminCalendar from './calendar'
import Resources from './resources'
import Users from './users'
import Settings from './settings'
import Prices from './prices'
import Openinghours from './openinghours'

import Sidebar from "./sidebar"
import Constraints from './openinghours'

function Admin () {
  const history = useHistory()

  return (
    <div style={{ height: '100%', display: 'flex' }}>
      <Router history={history}>
        <Sidebar/>
        <div style={{ flex: 1, display: 'flex', margin: '1em', overflow: 'auto' }}>
          <Switch>
            <Route exact path="/admin">
              <Redirect to="/admin/calendar" />
            </Route>
            <Route exact path="/admin/calendar">
              <AdminCalendar />
            </Route>
            <Route exact path="/admin/bookings">
              <BookingsList />
            </Route>
            <Route exact path="/admin/resources">
              <Resources />
            </Route>
            <Route exact path="/admin/openinghours">
              <Openinghours />
            </Route>
            <Route path="/admin/resources/:id">
              <Constraints />
            </Route>
            <Route exact path="/admin/users">
              <Users />
            </Route>
            <Route exact path="/admin/prices">
              <Prices />
            </Route>
            <Route exact path="/admin/settings">
              <Settings />
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  )
}

export default Admin