import { useEffect } from 'react'
import { useAsync } from 'react-async'
import { actions as notificationActions } from '../../../redux/notification'
import { connect } from 'react-redux'

import TicketPrices from './ticketPrices/index'
import Roles from './roles/index'
import RolePrices from './role-prices/index'
import Loader from '../../../components/loader'

import styles from './index.module.scss'

import { 
  getRoles,
  getTicketPricesList,
  getRolePricesList,
  createTicketPrice,
  deleteTicketPrice,
  updateTicketPrice,
  createRole,
  deleteRole,
  updateRole,
  createRolePrice,
  deleteRolePrice,
  updateRolePrice,
  assignResourcesToRole
} from '../../../lib/api'
import omit from 'lodash/omit'

const rolesFetcher = () => getRoles()
const ticketPricesFetcher = () => getTicketPricesList()
const rolePricesFetcher = () => getRolePricesList()

function mapDispatchToProps (dispatch) {
  return {
    addNotification: (text, options) => dispatch(notificationActions.addNotification(text, options)),
  }
}

function Prices ({ addNotification }) {
  const { data: roles = [], isPending: rolesLoading, run: getRoleData } = useAsync({
    deferFn: rolesFetcher
  })

  const { data: ticketPrices = [], isPending: ticketPricesLoading, run: getTicketPriceData } = useAsync({
    deferFn: ticketPricesFetcher
  })

  const { data: rolePrices = [], isPending: rolesPricesLoading, run: getRolePricesData } = useAsync({
    deferFn: rolePricesFetcher
  })

  useEffect(() => {
    getTicketPriceData()
    getRoleData()
    getRolePricesData()
  }, [
    getTicketPriceData,
    getRoleData,
    getRolePricesData
  ])

  const createTicketPriceHandler = async (values) => {
    await createTicketPrice(values)
    getTicketPriceData()
    addNotification('ticket price created')
  }

  const deleteTicketPriceHandler = async (ticketPriceID) => {
    await deleteTicketPrice(ticketPriceID)
    getTicketPriceData()
    addNotification('ticket price deleted')
  }

  const updateTicketPriceHandler = async (values) => {
    await updateTicketPrice(values.ticketPriceID, omit(values, ['ticketPriceID', 'organisationID']))
    getTicketPriceData()
    addNotification('ticket price edited')
  }

  const createRoleHandler = async (values) => {
    await createRole(values)
    getRoleData()
    addNotification('role created')
  }

  const updateRoleHandler = async (values) => {
    await updateRole(values.roleID, omit(values, ['roleID', 'organisationID', 'resources']))
    getRoleData()
    addNotification('role created')
  }
  
  const deleteRoleHandler = async (roleID) => {
    await deleteRole(roleID)
    getRoleData()
    addNotification('role deleted')
  }

  const assignResourcesToRoleHandler = async (values) => {
    await assignResourcesToRole(values.roleID, values.resourceIDs)
    getRoleData()
  }

  const createRolePriceHandler = async (values) => {
    await createRolePrice(values)
    getRolePricesData()
    addNotification('role price created')
  }

  const deleteRolePriceHandler = async (rolePriceID) => {
    await deleteRolePrice(rolePriceID)
    getRolePricesData()
    addNotification('role price deleted')
  }

  const updateRolePriceHandler = async (values) => {
    await updateRolePrice(values.rolePriceID, omit(values, ['rolePriceID', 'organisationID', 'parsedDuration']))
    getRolePricesData()
    addNotification('role price updated')
  }

  const loading =
    rolesLoading || 
    ticketPricesLoading || 
    rolesPricesLoading

  if (loading) return <Loader />

  return (
    <div className={styles.container}>
      <TicketPrices
        ticketPrices={ticketPrices}
        createTicketPrice={createTicketPriceHandler}
        deleteTicketPrice={deleteTicketPriceHandler}
        updateTicketPrice={updateTicketPriceHandler}
        roles={roles}
      />
      <br/>
      <Roles
        roles={roles}
        createRole={createRoleHandler}
        deleteRole={deleteRoleHandler}
        updateRole={updateRoleHandler}
        assignResourcesToRole={assignResourcesToRoleHandler}
      />
      <br/>
      <RolePrices 
        rolePrices={rolePrices}
        roles={roles}
        createRolePrice={createRolePriceHandler}
        deleteRolePrice={deleteRolePriceHandler}
        updateRolePrice={updateRolePriceHandler}
      />
    </div>
  )
}

export default connect(
  null,
  mapDispatchToProps
)(Prices)