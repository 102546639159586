import { useState } from 'react'
import { Table } from 'react-bootstrap'
import { BsPlusCircleFill } from 'react-icons/bs'
import { RiDeleteBinLine, RiEditBoxFill } from 'react-icons/ri'

import RoleCreateModal from './role-create-modal'
import RoleDeleteModal from './role-delete-modal'

import styles from './index.module.scss'

function Roles ({ roles, createRole, deleteRole, updateRole }) {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [toEdit, setToEdit] = useState(undefined)
  const [toDelete, setToDelete] = useState(undefined)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  return (
    <>
      <div className={styles.tableHeader}>
        <h2>Roles</h2>
        <BsPlusCircleFill onClick={() => setShowCreateModal(true)}/>
      </div>
      <Table borderless={true} striped={true}>
        <thead>
          <tr>
            <th>Role name</th>
            <th>Role description</th>
          </tr>
        </thead>
        <tbody>
          {roles.map(role => {
            return (
              <tr key={role.roleID}>
                <td>{role.name}</td>
                <td>{role.description}</td>
                <td>
                  <RiDeleteBinLine
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setToDelete(role)
                      setShowDeleteModal(true)
                    }}
                  />
                  <RiEditBoxFill
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setToEdit(role)
                      setShowEditModal(true)
                    }}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>

      <RoleCreateModal 
        show={showCreateModal} 
        create={(values) => {
          setShowCreateModal(false)
          createRole(values)
        }}
        handleClose={() => setShowCreateModal(false)} 
      />

      {toDelete && (
        <RoleDeleteModal 
          show={showDeleteModal} 
          role={toDelete}
          deleteRole={() => {
            setShowDeleteModal(false)
            deleteRole(toDelete.roleID)
          }}
          handleClose={() => setShowDeleteModal(false)} 
        />
      )}

      {toEdit && (
        <RoleCreateModal 
          show={showEditModal}
          role={toEdit}
          create={(values) => {
            setShowEditModal(false)
            updateRole(values)
          }}
          handleClose={() => setShowEditModal(false)} 
        />
      )}
    </>
  )
}

export default Roles
