import { useEffect } from 'react'
import { useAsync } from 'react-async'
import { actions as notificationActions } from '../../../redux/notification'
import { connect } from 'react-redux'
import { isEmpty, omit } from 'lodash'

import Resources from './resource/index'
import Loader from '../../../components/loader'

import styles from './index.module.scss'

import { 
  getResourcesAdmin,
  getRoles,
  createResource,
  deleteResource,
  saveResource,
  getCalendars,
  assignResources
} from '../../../lib/api'

const resourceFetcher = () => getResourcesAdmin()
const rolesFetcher = () => getRoles()
const calendarsFetcher = () => getCalendars()

function mapDispatchToProps (dispatch) {
  return {
    addNotification: (text, options) => dispatch(notificationActions.addNotification(text, options)),
  }
}

function AdminResources ({ addNotification }) {  
  const { data: resources = [], isPending: resourcesLoading, run: getResourcesAdminData } = useAsync({
    deferFn: resourceFetcher
  })
  const { data: roles = [], isPending: rolesLoading, run: getRoleData } = useAsync({
    deferFn: rolesFetcher
  })
  const { data: calendars = [], isPending: calendarsLoading, run: getCalendarsData } = useAsync({
    deferFn: calendarsFetcher
  })


  useEffect(() => {
    getResourcesAdminData()
    getRoleData()
    getCalendarsData()
  }, [
    getResourcesAdminData,
    getRoleData,
    getCalendarsData
  ])


  const createResourceHandler = async (values) => {
    const { resource, roleIDs } = values
    const resourceID = await createResource(resource)
    const parsedRoleIDS = roleIDs.map(r => parseInt(r))
    assignResources(resourceID, parsedRoleIDS)

    addNotification('resource created')
    await getResourcesAdminData()
  }

  const deleteResourceHandler = async (resourceID) => {
    await deleteResource(resourceID)
    addNotification('resource deleted')
    getResourcesAdminData()
  }

  const saveResourceHandler = async (resourceID, values) => {
    const { resource, roleIDs } = values

    resource.isFree = resource.isFree === 1 ? true : false

    await saveResource(resourceID, omit(resource, ['resourceID', 'organisationID', 'roles', 'roleIDs']))

    if (!isEmpty(roleIDs)) {
      const parsedRoleIDS = roleIDs.map(r => parseInt(r))
      assignResources(resourceID, parsedRoleIDS)
    }
    
    addNotification('resource saved')
    await getResourcesAdminData()
  }

  const loading = resourcesLoading || rolesLoading || calendarsLoading

  if (loading) return <Loader />

  return (
    <div className={styles.container}>
      <Resources
        resources={resources}
        createResource={createResourceHandler}
        deleteResource={deleteResourceHandler}
        saveResource={saveResourceHandler}
        roles={roles}
        calendars={calendars}
      />
    </div>
  )
}

export default connect(
  null,
  mapDispatchToProps
)(AdminResources)
