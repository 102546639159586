import { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { ToastProvider, useToasts, DefaultToastContainer, DefaultToast } from 'react-toast-notifications'

import Main from './containers/main'
import Login from './containers/login'
import Register from './containers/register'
import ResetPassword from './pages/password-reset'

import styles from './app.module.scss'

function mapStateToProps (state) {
  return {
    notification: state.notification
  }
}

const CustomToastContainer = props => <DefaultToastContainer style={{ maxWidth: '80%' }} {...props} />;
const CustomToast = props => <DefaultToast style={{ maxWidth: '100%' }} {...props} />;

function App({ notification }) {
  return (
    <div className={styles.body} >
      <div className={styles.container}>
        <ToastProvider components={{ ToastContainer: CustomToastContainer, Toast: CustomToast }}>
          <Content notification={notification}/>
        </ToastProvider>
      </div>
    </div>
  )
}

function Content ({ notification }) {
  const { addToast } = useToasts()

  useEffect(() => {
    if (isEmpty(notification)) return
    const { text, options } = notification
    addToast(text, options)
  },[notification, addToast])

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/login'>
          <Login />
        </Route>
        <Route exact path='/reset-password'>
          <ResetPassword />
        </Route>
        <Route exact path='/register'>
          <Register />
        </Route>
        <Route path='/'>
          <Main />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default connect(
  mapStateToProps
)(App)
