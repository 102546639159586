import { get } from 'lodash'
import axios from 'axios'

import store from '../redux/store'
import { actions as notificationActions } from '../redux/notification'

axios.interceptors.response.use(undefined, function (err) {
  const message = get(err, 'response.data', 'something went wrong')
  store.dispatch(notificationActions.addNotification(message.message || message, { appearance: 'error' }))
  return Promise.reject(err)
})