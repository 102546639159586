import jwt from 'jsonwebtoken'
import { pick } from 'lodash'

function checkLoginState () {
  const token = localStorage.getItem('token')

  if (!token) return false

  return !checkTokenExpired(token)
}

function getToken () {
  return localStorage.getItem('token')
}

function setToken (token) {
  return localStorage.setItem('token', token)
}

function checkTokenExpired (token) {
  const decoded = jwt.decode(token, { complete: true })

  return decoded.exp && decoded.exp < new Date().getTime()
}

function logout (history) {
  localStorage.clear()
  history.push('/login')
}

function getFromToken (keys = []) {
  const token = getToken()
  const decoded = jwt.decode(token)
  return pick(decoded, keys)
}

// helper function to check if the current user is an admin
function isAdmin () {
  return getFromToken(['isAdmin']).isAdmin === true
}

export {
  checkLoginState,
  getToken,
  setToken,
  logout,
  getFromToken,
  isAdmin
}