import format from "date-fns/format"
import { Button, Table, Form } from "react-bootstrap"
import { map } from "lodash"
import styles from "./user-table.module.scss"

function UserDetailTable({
  user,
  resendActivation,
  changeUserRole,
  roles,
  ticketAdd,
  ticketRemove,
  setUserToDelete,
  setShowUserDeleteModal,
}) {
  const {
    firstname,
    lastname,
    email,
    street,
    streetNumber,
    birthdate,
    isVerified,
    tickets,
    userID,
    municipality,
    postalCode,
    telephone,
  } = user

  if (!user) return null

  return (
    <Table>
      <tr>
        <td>Name</td>
        <td>
          {firstname} {lastname}
        </td>
      </tr>

      <tr>
        <td>Email</td>
        <td>{email}</td>
      </tr>

      <tr>
        <td>Address</td>
        <td>
          {street} {streetNumber}, {postalCode} {municipality}
        </td>
      </tr>

      <tr>
        <td>Birthdate</td>
        <td>{format(new Date(birthdate), "dd-MM-yyyy")}</td>
      </tr>

      <tr>
        <td>Telephone</td>
        <td>{telephone}</td>
      </tr>

      <tr>
        <td>Verified</td>
        <td>
          {isVerified ? (
            "Yes"
          ) : (
            <Button
              onClick={() => resendActivation(userID)}
              variant="outline-primary"
            >
              Resend activation
            </Button>
          )}
        </td>
      </tr>

      <tr>
        <td>Role</td>
        <td>
          <RoleSelect
            roles={roles}
            selectedRole={user.roleID}
            onChange={(e) => changeUserRole(userID, parseInt(e.target.value))}
          />
        </td>
      </tr>

      <tr>
        <td>Tickets</td>
        <td>
          <b>{tickets}</b>
        </td>
      </tr>

      <tr>
        <td>Actions</td>
        <td>
          <div className={styles.iconGroup}>
            <Button
              onClick={() => ticketAdd(userID, 1)}
              variant="outline-primary"
            >
              Add ticket
            </Button>
            <Button
              onClick={() => ticketAdd(userID, 5)}
              variant="outline-primary"
            >
              Add 5 tickets
            </Button>
            <Button
              onClick={() => ticketRemove(userID, 1)}
              variant="outline-danger"
            >
              Remove ticket
            </Button>
            <Button
              onClick={() => ticketRemove(userID, 5)}
              variant="outline-danger"
            >
              Remove 5 tickets
            </Button>
            <Button
              onClick={() => {
                setUserToDelete(user)
                setShowUserDeleteModal(true)
              }}
              variant="outline-danger"
            >
              Delete user
            </Button>
          </div>
        </td>
      </tr>
    </Table>
  )
}

const RoleSelect = ({ roles, onChange, selectedRole }) => {
  return (
    <div className={styles.select}>
      <Form.Control
        as="select"
        custom
        onChange={onChange}
        name="roleID"
        value={selectedRole}
      >
        {map(roles, (role) => {
          const { roleID, name } = role
          return (
            <option key={roleID} value={roleID}>
              {name}
            </option>
          )
        })}
      </Form.Control>
    </div>
  )
}

export default UserDetailTable
