import { useCallback, useEffect, useState } from "react"
import { useLocation, useHistory } from "react-router-dom"

function useQuery () {
	const [query, setQuery] = useState(new URLSearchParams())
	const { search } = useLocation()
	const history = useHistory()

	useEffect(() => {
		setQuery(new URLSearchParams(search))
	}, [ search ])
	
	const deleteFromQuery = useCallback((key) => {
		// prevent state mutation
		const newQuery = new URLSearchParams(query.toString)
		newQuery.delete(key)
		history.replace({
			search: newQuery.toString()
		})
	}, [query, history])

	return {
		deleteFromQuery,
		query
	}
}

export default useQuery