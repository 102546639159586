import { useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useFormik } from 'formik'
import { map, first, omit } from 'lodash'

import Thumb from './thumb'

import styles from './resource-create-edit-modal.module.scss'

const validate = values => {
  const errors = {}

  if (!values.name) {
    errors.name = 'Required'
  }

  return errors
}

const defaultValues = {
  name: '',
  imageName: '',
  description: '',
  capacity: 1,
  calendarID: 0,
  roleIDs: [],
}

function ResourceCreateModal({ show, handleClose, saveResource, resource, calendars, roles }) {
  // add checked state
  const [checked, setChecked] = useState(false)

  let initialValues = defaultValues
  initialValues.isFree = checked

  if (calendars.length > 0) {
    const defaultCalendar = first(calendars)
    initialValues.calendarID = defaultCalendar.calendarID || 0
  }

  if (resource && resource.name) {
    initialValues = {
      ...resource
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate,
    onSubmit: values => {
      values.calendarID = parseInt(values.calendarID)
      const roleIDs = values.roleIDs
      if (typeof values.imageName === "object" && values.imageName) {
        const reader = new FileReader()
        reader.readAsDataURL(values.imageName)
  
        reader.addEventListener("load", function () {
          // convert image file to base64 string
          saveResource({
            resource: {
              ...omit(resource, ['roleIDs']),
              imageName: reader.result
            },
            roleIDs: roleIDs
          })
        }, false)
      } else {
        saveResource({
          resource: values,
          roleIDs: roleIDs
        })
      }
    },
  })

  return (
    <>
      <Modal 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered 
        show={show} 
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          {resource ? (
            <Modal.Title>Edit Resource</Modal.Title>
          ): (
            <Modal.Title>Create Resource</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group>
              <Form.Label>Resource name</Form.Label>
              <Form.Control
                placeholder="Name"
                required
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <Form.Text className="text-muted">
                This resource name will be displayed in the user side of the application
              </Form.Text>
              {formik.touched.name && formik.errors.name ? (
                <div className={styles.errorField}>{formik.errors.name}</div>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label>Resource description</Form.Label>
              <Form.Control
                placeholder="Description"
                required
                name="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
              />
              <Form.Text className="text-muted">
                Describe your resource so the users know what it stands for
              </Form.Text>
              {formik.touched.description && formik.errors.description ? (
                <div className={styles.errorField}>{formik.errors.description}</div>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label>Capacity</Form.Label>
              <Form.Control
                placeholder="Capacity"
                required
                name="capacity"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.capacity}
              />
              <Form.Text className="text-muted">
                Amount of persons that can book 1 slot
              </Form.Text>
              {formik.touched.capacity && formik.errors.capacity ? (
                <div className={styles.errorField}>{formik.errors.capacity}</div>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label>Free</Form.Label>
              <Form.Check 
                type="switch"
                id="custom-switch"
                label="Is free?"
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
              <Form.Text className="text-muted">
                If you enable "free mode" then any user can book for free
              </Form.Text>
            </Form.Group>

            <Form.Group controlId='calendarID'>
              <Form.Label>Link openinghours</Form.Label>
              <Form.Control
                as="select"
                custom
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.calendarID}
                name="calendarID"
              >
                {
                  map(calendars, calendar => {
                    const { name, calendarID } = calendar
                    return (
                      <option key={calendarID} value={calendarID}>
                        { name }
                      </option>
                    )
                  })
                }
              </Form.Control>
            </Form.Group>

            <RoleSelect
              roles={roles}
              onChange={formik.handleChange}
              value={formik.values.roleIDs}
            />

            <Form.Group>
              <label for="file">Set an image</label>
              <input id="file" name="imageName" type="file" style={{ height: 50 }} onChange={(event) => {
                formik.setFieldValue("imageName", event.currentTarget.files[0]);
              }} className="form-control" />
              <Thumb file={formik.values.imageName} />
            </Form.Group>

            <div className={styles.buttons}>
              <Button variant="outline-secondary" onClick={handleClose}>
                Close
              </Button>
              <Button className={styles.confirmButton} type='submit'>
                Create
              </Button> 
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

const RoleSelect = ({ roles, onChange, value }) => {
  return (
    <div className={styles.select}>
      <span>Select a (or multiple) roles to link</span>
      <Form.Control as="select" multiple custom onChange={onChange} name='roleIDs' value={value}>
        {
          map(roles, role => {
            const { name, roleID } = role
            return (
              <option key={roleID} value={roleID}>
                {name}
              </option>
            )
          })
        }
      </Form.Control>
    </div>
  )
}

export default ResourceCreateModal
