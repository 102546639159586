import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import App from './App'
import store from './redux/store'
import config from './config'

// put interceptors here to prevent circular dependencies
import './lib/interceptors'

import './index.scss'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'

const {
  REACT_APP_BACKEND_URL
} = config

ReactDOM.render(
  <Provider store={store}>
    <link rel="stylesheet" href={`${REACT_APP_BACKEND_URL}/branding/branding.css`} />
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
)
