import { Spinner } from 'react-bootstrap'

import styles from './loader.module.scss'

function Loader () {
  return (
    <div className={styles.loader}>
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  )
}

export default Loader