import axios from 'axios'
import { defaultsDeep, omitBy, isNil } from 'lodash'

import { getToken } from './auth'
import config from '../config'

const {
  REACT_APP_BACKEND_URL
} = config

export async function login (email, password) {
  return _request({
    url: '/api/login',
    method: 'POST',
    data: {
      email,
      password
    }
  })
}

export async function register (data) {
  return _request({
    url: '/api/users/create',
    method: 'POST',
    data
  })
}

export async function profile () {
  return _request({
    url: '/api/users/me'
  })
}

export async function getResources () {
  return _request({
    url: '/api/resources/list'
  })
}

export async function getResourcesAdmin () {
  return _request({
    url: '/api/resources'
  })
}

export async function getAvailability (resources = [], from, to) {
  return _request({
    method: 'POST',
    url: '/api/availability',
    data: {
      from,
      to,
      resources
    }
  })
}

export async function getAvailabilityAdmin (resources = [], from, to) {
  return _request({
    method: 'POST',
    url: '/api/availability/admin',
    data: {
      from,
      to,
      resources
    }
  })
}

export async function createBooking (booking) {
  return _request({
    method: 'POST',
    url: '/api/bookings',
    data: [
      {
        ...booking,
        status: 'confirmed'
      }
    ]
  })
}

export async function createBookingAdmin (bookings) {
  return _request({
    method: 'POST',
    url: '/api/bookings/create',
    data: bookings
  })
}


export async function deleteBookings (booking) {
  return _request({
    method: 'DELETE',
    url: '/api/bookings',
    data: [booking]
  })
}

export async function getBookings () {
  return _request({
    url: '/api/bookings'
  })
}

export async function getDefaultRole () {
  return _request({
    url: '/api/roles/default'
  })
}

export async function getRolePrices () {
  return _request({
    url: '/api/prices/roles'
  })
}

export async function getRolePricesList () {
  return _request({
    url: '/api/prices/roles/list'
  })
}

export async function getTicketPrices () {
  return _request({
    url: '/api/prices/tickets'
  })
}

export async function getTicketPricesList () {
  return _request({
    url: '/api/prices/tickets/list'
  })
}

export async function getTickets () {
  return _request({
    url: '/api/tickets'
  })
}

export async function getAllBookings (start, end) {
  return _request({
    method: 'POST',
    url: '/api/bookings/list',
    data: {
      start,
      end
    }
  })
}

export async function getRoles () {
  return _request({
    url: '/api/roles'
  })
}

export async function deleteBookingsUser (bookings) {
  return _request({
    method: 'POST',
    url: '/api/bookings/delete',
    data: bookings
  })
}

export async function createTicketPayment (ticketPriceID) {
  return _request({
    method: 'POST',
    url: '/api/prices/tickets/charge',
    data: {
      ticketPriceID
    }
  })
}

export async function createRolePayment (rolePriceID) {
  return _request({
    method: 'POST',
    url: '/api/prices/roles/charge',
    data: {
      rolePriceID
    }
  })
}

export async function createResource (resource) {
  return _request({
    method: 'POST',
    url: '/api/resources',
    data: resource
  })
}

export async function saveResource (resourceID, resource) {
  return _request({
    method: 'PUT',
    url: `/api/resources/${resourceID}`,
    data: omitBy(resource, isNil)
  })
}

export async function getUsers () {
  return _request({
    url: '/api/users/list'
  })
}

export async function deleteResource (resourceID) {
  return _request({
    method: 'DELETE',
    url: `/api/resources/${resourceID}`
  })
}

export async function createTicketPrice (ticketPrice) {
  return _request({
    method: 'POST',
    url: '/api/prices/tickets',
    data: ticketPrice
  })
}

export async function updateTicketPrice (ticketPriceID, ticketPrice) {
  return _request({
    method: 'PUT',
    url: `/api/prices/tickets/${ticketPriceID}`,
    data: ticketPrice
  })
}

export async function deleteTicketPrice (ticketPriceID) {
  return _request({
    method: 'DELETE',
    url: `/api/prices/tickets/${ticketPriceID}`
  })
}

export async function createRole (role) {
  return _request({
    method: 'POST',
    url: '/api/roles',
    data: role
  })
}

export async function updateRole (roleID, role) {
  return _request({
    method: 'PUT',
    url: `/api/roles/${roleID}`,
    data: role
  })
}

export async function deleteRole (roleID) {
  return _request({
    method: 'DELETE',
    url: `/api/roles/${roleID}`
  })
}

export async function createRolePrice (rolePrice) {
  return _request({
    method: 'POST',
    url: '/api/prices/roles',
    data: rolePrice
  })
}

export async function updateRolePrice (rolePriceID, rolePrice) {
  return _request({
    method: 'PUT',
    url: `/api/prices/roles/${rolePriceID}`,
    data: rolePrice
  })
}

export async function deleteRolePrice (rolePriceID) {
  return _request({
    method: 'DELETE',
    url: `/api/prices/roles/${rolePriceID}`
  })
}

export async function updateUser (userID, user) {
  return _request({
    method: 'PUT',
    url: `/api/users/update/${userID}`,
    data: omitBy(user, isNil)
  })
}

export async function updateProfile (user) {
  return _request({
    method: 'PUT',
    url: `/api/users/update`,
    data: user
  })
}

export async function deleteUser (userID) {
  return _request({
    method: 'DELETE',
    url: `/api/users/delete/${userID}`
  })
}

export async function organisationGet () {
  return _request({
    url: '/api/organisation'
  })
}

export async function organisationInfo () {
  return _request({
    url: '/api/organisation/info'
  })
}

export async function updateOrganisation (organisation) {
  return _request({
    method: 'PUT',
    url: `/api/organisation`,
    data: organisation
  })
}

export async function organisationBranding () {
  return _request({
    url: '/api/organisation/branding'
  })
}

export async function updateOrganisationBranding (branding) {
  return _request({
    method: 'PUT',
    url: `/api/organisation/branding`,
    data: branding
  })
}

export async function resetPassword (email) {
  return _request({
    method: 'PUT',
    url: `api/users/password/reset`,
    data: { email }
  })
}

export async function assignResourcesToRole (roleID, resourceIDs) {
  return _request({
    method: 'PUT',
    url: `/api/roles/${roleID}/assign`,
    data: resourceIDs
  })
}

export async function createCalendar (calendar) {
  return _request({
    method: 'POST',
    url: `/api/calendars`,
    data: calendar
  })
}

export async function updateCalendar (calendarID, calendar) {
  return _request({
    method: 'PUT',
    url: `/api/calendars/${calendarID}`,
    data: calendar
  })
}

export async function getCalendars () {
  return _request({
    url: '/api/calendars'
  })
}

export async function deleteCalendar (id) {
  return _request({
    method: 'DELETE',
    url: `/api/calendars/${id}`
  })
}

export async function assignResources (resourceID, rolesIDs) {
  return _request({
    method: 'PUT',
    url: `/api/resources/${resourceID}/roles`,
    data: rolesIDs
  })
}

export async function createOverride (start, end, resourceID) {
  return _request({
    url: '/api/calendars/override',
    method: 'POST',
    data: {
      start,
      end,
      resourceID
    }
  })
}

export async function deleteOverride (overrideID) {
  return _request({
    method: 'DELETE',
    url: `/api/calendars/override/${overrideID}`
  })
}

export async function getOverrides () {
  return _request({
    url: '/api/calendars/override'
  })
}

export async function resendActivation (userId) {
  return _request({
    method: 'PUT',
    url: `/api/users/resend/${userId}`
  })
}

async function _request (options, fullResponse = false) {
  options = defaultsDeep(options, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getToken()}`
    },
    baseURL: REACT_APP_BACKEND_URL
  })

  return axios(options)
    .then(res => fullResponse ? res : res.data)
}
