import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'notification',
  initialState: {},
  reducers:{
    addNotification: {
      reducer: (state, action) => {
        Object.assign(state, action.payload)
      },
      prepare: (text, options) => {
        options = Object.assign({
          appearance: 'success',
          autoDismiss: true
        }, options)

        return { payload: { text, options } }
      }
    }
  }
})

export const { reducer, actions } = slice