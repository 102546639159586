import React, { useRef, useState } from 'react'

import { format } from 'date-fns'
import FocusTrap from 'focus-trap-react'
import { DayPicker } from 'react-day-picker'
import { usePopper } from 'react-popper'

import 'react-day-picker/dist/style.css'
import './new-picker.scss'

export default function DatePickerDialog({ day, selectDay }) {
  const [isPopperOpen, setIsPopperOpen] = useState(false)

  const popperRef = useRef(null)
  const [popperElement, setPopperElement] = useState(null)

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start'
  })

  const closePopper = () => {
    setIsPopperOpen(false)
    // document.getElementById('datepickerinput').blur()
  }

  const openPopper = () => {
    setIsPopperOpen(true)
  }

  return (
    <div>
      <div className={'dateInput'} ref={popperRef} onClick={openPopper}>
        <span>{format(day, 'dd-MM-y')}</span>
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper
          }}
        >
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className="dialog-sheet"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
            id={'datepickerinput2'}
          >
            <DayPicker
              initialFocus={isPopperOpen}
              mode="single"
              defaultMonth={day}
              selected={day}
              onSelect={(e) => {
                if (!e) return
                closePopper()
                selectDay(e)
              }}
              weekStartsOn={1}
            />
          </div>
        </FocusTrap>
      )}
    </div>
  )
}
