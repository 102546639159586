import { Modal, Button } from 'react-bootstrap'

import styles from './constraint-create-modal.module.scss'
import moment from 'moment'

function ConstrainCreateModal({ show, handleClose, saveOverride, start, end }) {

  return (
    <>
      <Modal 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered 
        show={show} 
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Close a time window</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <p>Are you sure you want to close: {moment(start).format("dd HH:mm")} - {moment(end).format("dd HH:mm")} </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="outline-primary" onClick={() => saveOverride()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ConstrainCreateModal