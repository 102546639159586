import { useFormik } from 'formik'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { omit } from 'lodash'

import styles from './index.module.scss'

const validate = values => {
  const errors = {}

  if (!values.name) {
    errors.name = 'Required'
  }

  if (!values.email) {
    errors.email = 'Required'
  }

  if (!values.street) {
    errors.street = 'Required'
  }

  if (!values.municipality) {
    errors.municipality = 'Required'
  }

  if (!values.postalCode) {
    errors.postalCode = 'Required'
  }

  if (!values.telephone) {
    errors.telephone = 'Required'
  }

  if (values.bookingLimit < 0) {
    errors.bookingLimit = 'Booking limit cannot be negative number'
  }

  if (!values.stripeKey) {
    errors.stripeKey = 'Required'
  }

  return errors
}

function OrganisationSettings ({ organisation, updateOrganisation }) {
  const formik = useFormik({
    initialValues: {
      ...organisation
    },
    validate,
    onSubmit: values => {
      updateOrganisation(omit(values, ['organisationID']))
    },
  })

  return (
    <div className={styles.settingsContainer}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <h3>Information Settings</h3>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            placeholder="Name"
            required
            name="name"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          <Form.Text className="text-muted">
            This name will be displayed to the end user
          </Form.Text>
          {formik.touched.name && formik.errors.name ? (
            <div className={styles.errorField}>{formik.errors.name}</div>
          ) : null}
        </Form.Group>

        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            placeholder="Email"
            required
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          <Form.Text className="text-muted">
            This email is used to send out emails
          </Form.Text>
          {formik.touched.email && formik.errors.email ? (
            <div className={styles.errorField}>{formik.errors.email}</div>
          ) : null}
        </Form.Group>

        <Form.Group>
          <Row noGutters>
            <Col>
              <Form.Label>Street</Form.Label>

              <Form.Control
                placeholder="Street"
                required
                name="street"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.street}
              />
              {formik.touched.street && formik.errors.street ? (
                <div className={styles.errorField}>{formik.errors.street}</div>
              ) : null}
            </Col>

            <Col>
              <Form.Label>Municipality</Form.Label>
              <Form.Control
                placeholder="Municipality"
                required
                name="municipality"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.municipality}
              />
              {formik.touched.municipality && formik.errors.municipality ? (
                <div className={styles.errorField}>{formik.errors.municipality}</div>
              ) : null}
            </Col>

            <Col>
              <Form.Label>Postal code</Form.Label>
              <Form.Control
                placeholder="Postal Code"
                required
                name="postalCode"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.postalCode}
              />
              {formik.touched.postalCode && formik.errors.postalCode ? (
                <div className={styles.errorField}>{formik.errors.postalCode}</div>
              ) : null}
            </Col>
          </Row>
        </Form.Group>

        <Form.Group>
          <Form.Label>Organisation Telephone</Form.Label>
          <Form.Control
            placeholder="Telephone"
            required
            name="telephone"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.telephone}
          />
          {formik.touched.telephone && formik.errors.telephone ? (
            <div className={styles.errorField}>{formik.errors.telephone}</div>
          ) : null}
        </Form.Group>

        <h3>User Settings</h3>
        <Form.Group>
          <Form.Label>Booking limit for users</Form.Label>
          <Form.Control
            placeholder="Booking Limit"
            required
            name="bookingLimit"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.bookingLimit}
            type='number'
          />
          {formik.touched.bookingLimit && formik.errors.bookingLimit ? (
            <div className={styles.errorField}>{formik.errors.bookingLimit}</div>
          ) : null}
        </Form.Group>
        <Form.Group>
          <Form.Label>Booking cancel limit for users in seconds</Form.Label>
          <Form.Control
            placeholder="Booking cancel limit"
            required
            name="bookingCancelLimit"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.bookingCancelLimit}
            type='number'
          />
          {formik.touched.bookingCancelLimit && formik.errors.bookingCancelLimit ? (
            <div className={styles.errorField}>{formik.errors.bookingCancelLimit}</div>
          ) : null}
        </Form.Group>

        <h3>Payment Settings</h3>
        <Form.Group>
          <Form.Label>Stripe key</Form.Label>
          <Form.Control
            placeholder="Stripe API key"
            required
            name="stripeKey"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.stripeKey}
            // type='password'
          />
          {formik.touched.stripeKey && formik.errors.stripeKey ? (
            <div className={styles.errorField}>{formik.errors.stripeKey}</div>
          ) : null}
        </Form.Group>

        <h3>Google Calendar settings</h3>
        <Form.Group>
          <Form.Label>Google calendar ID</Form.Label>
          <Form.Control
            placeholder="ID@group.calendar.google.com"
            required
            name="googleCalendarURL"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.googleCalendarURL}
            // type='password'
          />
          {formik.touched.googleCalendarURL && formik.errors.googleCalendarURL ? (
            <div className={styles.errorField}>{formik.errors.googleCalendarURL}</div>
          ) : null}
        </Form.Group>

        <Button className='savebtn' type='submit'>
          Save settings
        </Button> 
      </Form>
    </div>
  )
}

export default OrganisationSettings
