import { Card, Button } from 'react-bootstrap'
import { format } from 'date-fns'
import { AiOutlineClockCircle, AiOutlineCalendar } from 'react-icons/ai'

import styles from './reservation-card.module.scss'

function ReservationCard ({ name, description, start, end, handleShowDeleteConfirm, bookingID, width }) {
  return (
    <Card className={styles.card} style={{ width }}>
      <Card.Body>
        <Card.Title className={styles.title}>
          <p>{name}</p>
        </Card.Title>
        <div className={styles.description}>
          {
            description && <p>description: {description}</p>
          }
          <p><AiOutlineCalendar /> {format(start, 'dd-MM-yyyy')}</p>
          <p><AiOutlineClockCircle /> {format(start, 'HH:mm ')} - {format(end, 'HH:mm ')}</p>
        </div>
          {handleShowDeleteConfirm && (
            <Button className={styles.button} onClick={() => handleShowDeleteConfirm(bookingID)}>
              <span>Cancel</span>
            </Button>)
          }
      </Card.Body>
    </Card>
  )
}

export default ReservationCard