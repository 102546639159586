import { useState, useEffect } from 'react'
import { ListGroup, Form, Row, Col } from 'react-bootstrap'
import { format } from 'date-fns'
import { chain, map, isEmpty, groupBy } from 'lodash'
import moment from 'moment'
import { useAsync } from 'react-async'

import { getAllBookings, getResourcesAdmin } from '../../../lib/api'

import styles from './index.module.scss'

const bookingsFetcher = ([start, end]) => getAllBookings(start, end)
const resourceFetcher = () => getResourcesAdmin()

function BookingsList({ addNotification }) {
  const [calendarStartDate, setCalendarStartDate] = useState(new Date())
  const [calendarEndDate, setCalendarEndDate] = useState(new Date())
  const [selectedResource, setSelectedResource] = useState(undefined)
  const [calendarEvents, setCalendarEvents] = useState([])

  const { data: bookings = [], run: getBookings } = useAsync({
    deferFn: bookingsFetcher
  })

  const { data: resources = [], run: getResourcesAdminData } = useAsync({
    deferFn: resourceFetcher
  })

  useEffect(() => {
    getBookings()
    getResourcesAdminData()
  }, [getBookings, getResourcesAdminData])

  // get availability based on range
  useEffect(() => {
    if (!selectedResource || !calendarStartDate || !calendarEndDate) return
    const from = moment(calendarStartDate).startOf('day').valueOf()
    const to = moment(calendarEndDate).endOf('day').valueOf()
    getBookings(from, to)
  }, [selectedResource, calendarStartDate, calendarEndDate, getBookings])


  // set the initial selected resource
  useEffect(() => {
    if (selectedResource || isEmpty(resources)) return
    setSelectedResource(resources[0])
  }, [resources, setSelectedResource, selectedResource])

  // create events
  useEffect(() => {
    if (!selectedResource) return

    let events = []

    if (!isEmpty(bookings)) {
      events = [ ...events, ...createBookingEvents(bookings, selectedResource)]
      console.log(events)
      events = groupBy(events, event => moment(event.start).startOf('day'))
    }

    setCalendarEvents(events)
  }, [bookings, setCalendarEvents, selectedResource])

  const handleResourceSelectChange = (resourceID) => {
    const resource = resources.find(resource => resource.resourceID === parseInt(resourceID))
    setSelectedResource(resource)
  }

  const handleStartDateChange = (event) => {
    const newStart = new Date(event.target.value)
    setCalendarStartDate(newStart)

    let end = calendarEndDate
    if (calendarEndDate.valueOf() < newStart.valueOf()) {
      setCalendarEndDate(newStart)
      end = moment(newStart).endOf('day')
    }

    getBookings(newStart.valueOf(), end.valueOf())
  }

  const handleEndDateChange = (event) => {
    const d = new Date(event.target.value)
    const newEnd = moment(d).endOf('day')
    setCalendarEndDate(d)
    getBookings(calendarStartDate.valueOf(), newEnd.valueOf())
  }

  return (
    <div className={styles.container}>
      <h2>Bookings</h2>
      <Row noGutters className={styles.bookingContainer}>
        <Col md={2} lg={3}>
          <ResourceSelect resources={resources} selectedResource={selectedResource} handleChange={handleResourceSelectChange} />
        </Col>
        <Col md={4} lg={5}>
          <h4>Select a range of dates to filter</h4>
          <div className={styles.pickersContainer}>
            <div className={styles.picker}>
              <Form.Control
                type='date'
                value={`${format(calendarStartDate, 'yyyy-MM-dd')}`}
                onChange={handleStartDateChange}
              />
            </div>
            <div className={styles.pickerRight}>
              <Form.Control
                type='date'
                value={`${format(calendarEndDate, 'yyyy-MM-dd')}`}
                onChange={handleEndDateChange}
              />
            </div>
          </div>
        </Col>
      </Row>
      {map(calendarEvents, (groupedEvents, key) => {
        return (
          <div key={key} className={styles.eventGroup}>
            <h5>{`${format(new Date(key), 'yyyy-MM-dd')}`}</h5>
            <ListGroup>
              {groupedEvents.map(event => {
                return (
                  <ListGroup.Item key={event.id}>
                    {`${format(event.start, 'HH:mm')}`}-{`${format(event.end, 'HH:mm')}`}: {event.title}
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </div>
        )
      })}
    </div>
  )
}

const ResourceSelect = ({ selectedResource, resources, handleChange }) => {
  if (!selectedResource) return null

  return (
    <div className={styles.resourceSelection}>
      <h4>Select a calendar</h4>
      <Form.Control className={styles.select} as="select" custom value={selectedResource.resourceID} onChange={(e) => handleChange(e.target.value)}>
        {
          map(resources, resource => {
            const { resourceID, name } = resource
            return (
              <option key={resourceID} value={resource.resourceID}>
                {name}
              </option>
            )
          })
        }
      </Form.Control>
    </div>
  )
}

function createBookingEvents(bookings, resource) {
  return chain(bookings)
    .filter(booking => booking.resourceID === resource.resourceID)
    .sortBy(['start'])
    .map((booking, index) => {
      const { firstname, lastname, start, end, bookingID } = booking
      return {
        id: index,
        title: `${firstname} ${lastname}`,
        allDay: false,
        start: new Date(start),
        end: new Date(end),
        type: 'booking',
        bookingID
      }
    })
    .value()
}

export default BookingsList