import { useAsync } from 'react-async'
import { Button, Spinner } from 'react-bootstrap'
import { createBooking } from '../../lib/api'
import ReservationCard from '../../components/reservation-card'
import Loader from '../../components/loader'
import { FaChevronRight } from "react-icons/fa"
import { Redirect, useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

import styles from './detail.module.scss'

function DetailReservation ({ booking, resource, user, loading, getBookings, addNotification }) {
  const { name, capacity } = resource
  const { start, end } = booking
  const history = useHistory()
  
  const createBookingHandler = ([ ticketPriceID ]) => createBooking(ticketPriceID).then(() => {
    if(!error) {
      getBookings()
      addNotification('Booking created!')
      history.push('/reservations/list')
    }
  })
  const { run: confirmBooking, isPending: creatingBooking, error } = useAsync({ deferFn:  createBookingHandler })

  if (isEmpty(resource) || isEmpty(booking)) return <Redirect to={'/reservations/new'} />

  if (loading) return <Loader />

  // const filteredTickets = tickets.filter(ticket => resource.resourceTypes.some(type => type.resourceTypeID === ticket.resourceTypeID))
  const hasTickets = user.tickets > 0

  return (
    <>
      <div className={styles.detail}>
        <h3>{resource.name}</h3>
        <h2>Confirm reservation</h2>
        <ReservationCard
          name={name}
          start={start}
          end={end}
          capacity={capacity}
        />
        <div className={styles.costDetail}>
          <p>You have {user.tickets} ticket(s) left</p>
          {!hasTickets && (
            <span onClick={() => history.push('/tickets/buy')}>Show me where to buy tickets.</span>
          )}
        </div>
        <div className={styles.actionButtons}>
          <Button
            className={styles.cancelButton}
            onClick={() => history.push('/reservations/new')}
          >
            <span>
              Cancel
            </span>
          </Button>

          <Button 
            disabled={!hasTickets}
            className={styles.confirmButton}
            onClick={() => confirmBooking(booking)}
          >
            {creatingBooking && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {!creatingBooking && (
              <span>
                Confirm
              </span>
            )}
            <FaChevronRight />
          </Button>
        </div>
      </div>
    </>
  )
}

export default DetailReservation