import { Carousel } from 'react-responsive-carousel'
import { random } from 'lodash'

import styles from './resource-carousel.module.scss'
import config from '../config'

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './resource-carousel.scss'

const {
  REACT_APP_BACKEND_URL
} = config

function ResourceCarousel ({ resources }) {
  return (
    <Carousel
        showStatus={false}
        showThumbs={false}
        showArrows={false}
        autoPlay
        infiniteLoop
        interval={random(3000, 5000)}
      >
        {
          resources.map(resource => {
            const { imageName } = resource
            return (
              <img key={imageName} className={styles.resourceImage} src={`${REACT_APP_BACKEND_URL}/branding/resources/${imageName ? imageName : 'default.png'}`} alt='resourceImage'/>
            )
          })
        }
      </Carousel>
  )
}

export default ResourceCarousel