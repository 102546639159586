import React from 'react'

import config from '../../../../config'

const {
  REACT_APP_BACKEND_URL
} = config

class Thumb extends React.Component {
  state = {
    loading: false,
    thumb: undefined,
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.file) { return }

    const { file } = nextProps

    if (typeof file === 'string' && (file.includes('png') || file.includes('jpg'))) {
      const src = `${REACT_APP_BACKEND_URL}/branding/resources/${file ? file : 'default.png'}`
      this.setState({ loading: false, thumb: src })
      return
    }

    if (typeof file === 'string' && file.startsWith('https')) {
      this.setState({ loading: false, thumb: file })
      return
    }

    this.setState({ loading: true }, () => {
      let reader = new FileReader()

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result })
      }

      reader.readAsDataURL(file)
    })
  }

  render() {
    const { file } = this.props
    const { loading, thumb } = this.state

    if (!file) { return null }

    if (loading) { return <p>loading...</p> }

    return (<img src={thumb}
      alt={file.name}
      className="img-thumbnail mt-2"
      height={200}
      width={200} />)
  }
}

export default Thumb