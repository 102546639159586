import { Modal, Button, Form } from 'react-bootstrap'
import { useFormik } from 'formik'

import styles from './role-create-modal.module.scss'

const validate = values => {
  const errors = {}

  if (!values.name) {
    errors.name = 'Required'
  }

  return errors
}

function RoleCreateModal({ show, handleClose, create, role }) {
  const toEdit = !!role

  const defaults = {
    name: '',
    description: ''
  }

  const formik = useFormik({
    initialValues: toEdit ? role : defaults,
    validate,
    onSubmit: values => {
      create(values)
    },
  })

  return (
    <>
      <Modal 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered 
        show={show} 
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          {toEdit ? (
            <Modal.Title>Edit role</Modal.Title>
          ) : (
            <Modal.Title>Create role</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group>
              <Form.Label>Role name</Form.Label>
              <Form.Control
                placeholder="Name"
                required
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <Form.Text className="text-muted">
                This role name will be displayed in the user side of the application
              </Form.Text>
              {formik.touched.name && formik.errors.name ? (
                <div className={styles.errorField}>{formik.errors.name}</div>
              ) : null}
            </Form.Group>
            
            <Form.Group>
              <Form.Label>Role description</Form.Label>
              <Form.Control
                placeholder="Description about the role"
                required
                name="description"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
              />
              <Form.Text className="text-muted">
                This description will also be shown in the user side of the application.
              </Form.Text>
              {formik.touched.description && formik.errors.description ? (
                <div className={styles.errorField}>{formik.errors.description}</div>
              ) : null}
            </Form.Group>

            <div className={styles.buttons}>
              <Button className={styles.closeBtn} variant="outline-secondary" onClick={handleClose}>
                Close
              </Button>
              {toEdit ? (
                <Button className={styles.confirmButton} type='submit'>
                  Edit
                </Button>
              ) : (
                <Button className={styles.confirmButton} type='submit'>
                  Create
                </Button> 
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default RoleCreateModal
