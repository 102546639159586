import { useFormik } from 'formik'
import { Form, Button } from 'react-bootstrap'
import { omit } from 'lodash'

import styles from './index.module.scss'

const validateBranding = values => {
  const errors = {}

  if (!values.name) {
    errors.name = 'Required'
  }

  if (!values.domain) {
    errors.domain = 'Required'
  }

  if (!values.webDomain) {
    errors.webDomain = 'Required'
  }

  return errors
}

function BrandingSettings ({ branding, updateOrganisationBranding }) {
  const brandingFormik = useFormik({
    initialValues: {
      ...branding
    },
    validateBranding,
    onSubmit: values => {
      updateOrganisationBranding(omit(values, ['organisationID', 'brandingID']))
    },
  })

  return (
    <div className={styles.settingsContainer}>
      <Form noValidate onSubmit={brandingFormik.handleSubmit}>
        <h3>Branding Settings</h3>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            placeholder="Name"
            required
            name="name"
            onBlur={brandingFormik.handleBlur}
            onChange={brandingFormik.handleChange}
            value={brandingFormik.values.name}
          />
          <Form.Text className="text-muted">
            This name will be displayed to the end user
          </Form.Text>
          {brandingFormik.touched.name && brandingFormik.errors.name ? (
            <div className={styles.errorField}>{brandingFormik.errors.name}</div>
          ) : null}
        </Form.Group>

        <Form.Group>
          <Form.Label>Domain</Form.Label>
          <Form.Control
            placeholder="Domain"
            required
            name="domain"
            onBlur={brandingFormik.handleBlur}
            onChange={brandingFormik.handleChange}
            value={brandingFormik.values.domain}
          />
          <Form.Text className="text-muted">
            This domain will be used for email
          </Form.Text>
          {brandingFormik.touched.domain && brandingFormik.errors.domain ? (
            <div className={styles.errorField}>{brandingFormik.errors.domain}</div>
          ) : null}
        </Form.Group>

        <Form.Group>
          <Form.Label>Webdomain</Form.Label>
          <Form.Control
            placeholder="Webdomain"
            required
            name="webDomain"
            onBlur={brandingFormik.handleBlur}
            onChange={brandingFormik.handleChange}
            value={brandingFormik.values.webDomain}
          />
          <Form.Text className="text-muted">
            This domain will be used to validate accounts
          </Form.Text>
          {brandingFormik.touched.webDomain && brandingFormik.errors.webDomain ? (
            <div className={styles.errorField}>{brandingFormik.errors.webDomain}</div>
          ) : null}
        </Form.Group>

        <Button className="savebtnLarge" type='submit'>
          Save branding settings
        </Button> 
      </Form>
    </div>
  )
}

export default BrandingSettings