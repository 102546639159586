import { Modal, Button, Form } from 'react-bootstrap'
import { useFormik } from 'formik'
import { map } from 'lodash'
import styles from './role-price-create-modal.module.scss'

const validate = values => {
  const errors = {}

  if (!values.name) {
    errors.name = 'Required'
  }

  if (!values.price) {
    errors.price = 'Required'
  }
  if (values.price <= 0) {
    errors.price = 'Price must be greater than 0'
  }

  if (!values.duration) {
    errors.duration = 'Required'
  }
  if (values.duration <= 0) {
    errors.duration = 'Duration must be greater than 0 days'
  }

  return errors
}

function RoleCreateModal({ show, handleClose, create, roles, rolePrice }) {
  const toEdit = !!rolePrice

  const defaults = {
    name: '',
    roleID: roles[0].roleID,
    price: 0,
    duration: 0
  }

  const formik = useFormik({
    initialValues: toEdit ? rolePrice : defaults,
    validate,
    onSubmit: values => {
      values.roleID = parseInt(values.roleID)
      create(values)
    },
  })

  return (
    <>
      <Modal 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered 
        show={show} 
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          {toEdit ? (
            <Modal.Title>Edit Role Price</Modal.Title>
          ) : (
            <Modal.Title>Create Role Price</Modal.Title>
          )}        
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group>
              <Form.Label>Role price name</Form.Label>
              <Form.Control
                placeholder="Name"
                required
                name="name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <Form.Text className="text-muted">
                This role name will be displayed in the user side of the application
              </Form.Text>
              {formik.touched.name && formik.errors.name ? (
                <div className={styles.errorField}>{formik.errors.name}</div>
              ) : null}
            </Form.Group>

            <RoleSelect
              roles={roles}
              onChange={formik.handleChange}
            />

            <Form.Group>
              <Form.Label>Role price</Form.Label>
              <Form.Control
                required
                name="price"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.price}
                type='number'
              />
              <Form.Text className="text-muted">
                Price for this role
              </Form.Text>
              {formik.touched.price && formik.errors.price ? (
                <div className={styles.errorField}>{formik.errors.price}</div>
              ) : null}
            </Form.Group>

            <Form.Group>
              <Form.Label>Duration</Form.Label>
              <Form.Control
                required
                name="duration"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.duration}
                type='number'
              />
              <Form.Text className="text-muted">
                Duration for this role expressed in number of days.
              </Form.Text>
              {formik.touched.duration && formik.errors.duration ? (
                <div className={styles.errorField}>{formik.errors.duration}</div>
              ) : null}
            </Form.Group>

            <div className={styles.buttons}>
              <Button variant="outline-secondary" onClick={handleClose}>
                Close
              </Button>
              {toEdit ? (
                <Button className={styles.confirmButton} type='submit'>
                  Edit
                </Button>
              ) : (
                <Button className={styles.confirmButton} type='submit'>
                  Create
                </Button> 
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

const RoleSelect = ({ roles, onChange }) => {
  return (
    <div className={styles.select}>
      <span>Select a role</span>
      <Form.Control as="select" custom onChange={onChange} name='roleID'>
        {
          map(roles, role => {
            const { roleID, name } = role
            return (
              <option key={roleID} value={roleID} >
                {name}
              </option>
            )
          })
        }
      </Form.Control>
    </div>
  )
}

export default RoleCreateModal
