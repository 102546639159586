import { Modal, Button } from 'react-bootstrap'
import moment from 'moment'
import { AiOutlineClockCircle, AiOutlineCalendar } from 'react-icons/ai'

import styles from './booking-detail-modal.module.scss'

function BookingDetailModal({ handleClose, deleteBooking, event }) {
  const { title, end, start } = event

  return (
    <>
      <Modal 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered 
        show={true} 
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detail booking</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <span>User: {title}</span>
          <span><AiOutlineCalendar />Date: {moment(start).format('DD-MM')}</span>
          <span><AiOutlineClockCircle />{moment(start).format('HH:mm')} - {moment(end).format('HH:mm')}</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="outline-danger" onClick={deleteBooking}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default BookingDetailModal