import { useEffect, useState } from 'react'
import { Router, Switch, Route, useHistory, useLocation } from 'react-router-dom'
import { useAsync } from 'react-async'
import { find, isEmpty } from 'lodash'
import { connect } from 'react-redux'

import New from './new'
import List from './list'
import Detail from './detail'

import { getResources, getAvailability, getBookings, profile } from '../../lib/api'
import Loader from '../../components/loader'
import Banner from '../../components/banner'
import { actions as notificationActions } from '../../redux/notification'
import moment from 'moment'

import styles from './index.module.scss'

const resourceFetcher = () => getResources()
const availabilityFetcher = ([resourceID, from]) => getAvailability([resourceID], from)
const bookingFetcher = () => getBookings()
const userFetcher = () => profile()

function mapDispatchToProps (dispatch) {
  return {
    addNotification: (text, options) => dispatch(notificationActions.addNotification(text, options))
  }
}

function Reservations ({ addNotification }) {
  const history = useHistory()
  const location = useLocation()

  const [startDate, setStartDate] = useState(new Date())
  const [selectedResource, setSelectedResource] = useState({})
  const [selectedBooking, setSelectedBooking] = useState({})

  const { data: resources = [], isPending: resourcesLoading } = useAsync({
    promiseFn: resourceFetcher
  })

  const { data: availability = [], isPending: availabilityLoading, run: getAvailability } = useAsync({
    deferFn: availabilityFetcher
  })

  const { data: bookings = [], isPending: bookingLoading, run: getBookings } = useAsync({
    deferFn: bookingFetcher
  })

  const { data: user = {}, isPending: userLoading, run: getUser } = useAsync({
    deferFn: userFetcher
  })

  useEffect(() => {
    getBookings()
  }, [getBookings])

  useEffect(() => {
    getUser()
  }, [getUser])

  useEffect(() => {
    if (isEmpty(selectedResource)) return
    const start = moment(startDate).startOf('day').valueOf()
    getAvailability(selectedResource.resourceID, start)
  }, [selectedResource, startDate, getAvailability])

  useEffect(() => {
    if (!isEmpty(selectedResource) || isEmpty(resources)) return
    setSelectedResource(resources[0])
  }, [resources, setSelectedResource, selectedResource])

  function handleResourceSelect (id) {
    const fullResource = find(resources, ({ resourceID }) => resourceID === Number(id))
    setSelectedBooking({})
    setSelectedResource(fullResource)
  }

  function handleBookingSelect (booking) {
    setSelectedBooking(booking)
  }

  if (resourcesLoading) return <Loader />

  const loading = availabilityLoading || bookingLoading || userLoading

  return (
    <>
      {location.pathname !== '/reservations/new' && (
        <Banner />
      )}
      <div className={styles.container}>
        <Router history={history}>
          <Switch>
            <Route exact path='/reservations/new'>
              <New
                resources={resources}
                loading={loading}
                selectedResource={selectedResource}
                selectedBooking={selectedBooking}
                handleResourceSelect={handleResourceSelect}
                handleBookingSelect={handleBookingSelect}
                startDate={startDate}
                handleStartDateSelect={setStartDate}
                availability={availability}
                history={history}
              />
            </Route>
            <Route exact path='/reservations/detail'>
              <Detail
                booking={selectedBooking}
                resource={selectedResource}
                addNotification={addNotification}
                getBookings={getBookings}
                user={user}
              />
            </Route>
            <Route exact path='/reservations/list'>
              <List
                resources={resources}
                bookings={bookings}
                getBookings={getBookings}
                loading={loading}
                addNotification={addNotification}
              />
            </Route>
          </Switch>
        </Router>
      </div>
    </>
  )
}

export default connect(
  undefined,
  mapDispatchToProps
)(Reservations)