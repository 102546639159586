import { useEffect } from 'react'
import { Router, Switch, Route, useHistory, Redirect } from 'react-router-dom'
import { useAsync } from 'react-async'
import { connect } from 'react-redux'

import List from './list'
import Buy from './buy'

import { getResources, getTicketPrices, getRolePrices, getRoles, profile } from '../../lib/api'
import useInterval from '../../hooks/useInterval'

import Loader from '../../components/loader'
import Banner from '../../components/banner'
import useQuery from '../../hooks/useQuery'

import { actions as notificationActions } from '../../redux/notification'

import styles from './index.module.scss'

function mapDispatchToProps (dispatch) {
  return {
    addNotification: (text, options) => dispatch(notificationActions.addNotification(text, options))
  }
}

const resourceFetcher = () => getResources()
const ticketPriceFetcher = () => getTicketPrices()
const rolePricesFetcher = () => getRolePrices()
const rolesFetcher = () => getRoles()
const userFetcher = () => profile()

function Tickets ({ addNotification }) {
  const history = useHistory()
  const { query, deleteFromQuery } = useQuery()

  const { data: resources = [], isPending: resourcesLoading } = useAsync({
    promiseFn: resourceFetcher
  })
  const { data: ticketPrices = [], isPending: loadingTicketPrices } = useAsync({
    promiseFn: ticketPriceFetcher
  })
  const { data: rolePrices = [], isPending: rolePricesLoading } = useAsync({
    promiseFn: rolePricesFetcher
  })
  const { data: roles = [], isPending: rolesLoading } = useAsync({
    promiseFn: rolesFetcher
  })
  const { data: user = {}, isPending: userLoading, run: fetchUser } = useAsync({
    deferFn: userFetcher
  })

  useInterval(fetchUser, 50000)

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  useEffect(() => {
    if (query.get('payment') === 'success') {
      addNotification('Payment succesful')
    }

    if (query.get('payment') === 'failed') {
      addNotification('Payment failed', { appearance: 'error' })
    }
  
    deleteFromQuery('payment')
  }, [addNotification, query, deleteFromQuery])

  const isLoading = resourcesLoading || loadingTicketPrices || rolePricesLoading || rolesLoading || userLoading

  if (isLoading) return <Loader />

  return (
    <>
      <Banner />
      <div className={styles.container}>
        <Router history={history}>
          <Switch>
            <Route exact path='/tickets/list'>
              <List
                loading={isLoading}
                resources={resources}
                roles={roles}
                user={user}
              />
            </Route>
            <Route exact path='/tickets/buy'>
              <Buy
                resources={resources}
                ticketPrices={ticketPrices}
                loading={isLoading}
                rolePrices={rolePrices}
              />
            </Route>
            <Route path='*'>
              <Redirect to="/tickets/list" />
            </Route>
          </Switch>
        </Router>
      </div>
    </>
  )
}

export default connect(
  undefined,
  mapDispatchToProps
)(Tickets)
