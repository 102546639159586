import { RiDeleteBinLine } from 'react-icons/ri'
import { isEmpty } from 'lodash'
import { Table } from 'react-bootstrap'
import format from "date-fns/format"

const ConstraintsTable = ({ constraints, deleteConstraint }) => {
  if (!constraints || isEmpty(constraints)) return null

  return (
    <div style={{ marginTop: '1em' }}>
      <h4>Created openinghours</h4>
      <Table hover borderless={true} striped={true}>
        <thead>
          <tr>
            <th>Day</th>
            <th>From-To</th>
            <th>Time</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {constraints.map((constraint, index) => {
            const { day, startDate, endDate, start, end } = constraint
            return (
              <tr key={index}>
                <td>{day}</td>
                <td>{format(new Date(startDate), 'dd-MM-yyyy')} - {format(new Date(endDate), 'dd-MM-yyyy')}</td>
                <td>{start} - {end}</td>
                <td>
                  <RiDeleteBinLine
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      deleteConstraint(index)
                    }}
                  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default ConstraintsTable