import { useState } from 'react'
import { Col, Form, Row, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AiOutlineKey } from 'react-icons/ai'
import { FaChevronLeft } from "react-icons/fa"

import { resetPassword } from '../../lib/api'
import { actions as notificationActions } from '../../redux/notification'

import styles from './index.module.scss'

function mapDispatchToProps (dispatch) {
  return {
    addNotification: (text, options) => dispatch(notificationActions.addNotification(text, options))
  }
}

function ResetPassword({ addNotification }) {
  const [email, setEmail] = useState('')
  const [validated, setValidated] = useState(false)
  const [success, setSuccess] = useState(false)

  const history = useHistory()

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    event.preventDefault()
    event.stopPropagation()

    setValidated(true)

    if (form.checkValidity() === true) {
      resetPassword(email)
        .then(() => {
          addNotification('Success, check email')
          setSuccess(true)
          // history.push('/')
        })
        .catch(err => {
          setEmail('')
        })
    }
  };

  if (success) {
    return (
      <div className={styles.container}>
        <div className={styles.success}>
          <h2>Password reset complete!</h2>
          <span>Check your email in order to continue.</span>
          <Button
            className={styles.button}
            variant="primary"
            onClick={() => history.push('/login')}
          >Take me to Login</Button>
        </div>
      </div>
    )
  }

  return (
    <Row className={styles.container} noGutters={true}>
      <Col xs={10} md={8} lg={8} xl={6}>
        <div className={styles.inner}>
          <AiOutlineKey />
          <h2>Forgot Password?</h2>
          <span>No worries, we'll send you reset instructions.</span>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="formEmail">
              <Form.Label className={styles.formLabel}>Email address</Form.Label>
              <Form.Control
                className={styles.formText}
                type="email"
                placeholder="Enter email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}/>
              <Form.Control.Feedback type="invalid">
                Gelieve een geldig email adres in te vullen.
              </Form.Control.Feedback>
            </Form.Group>
            <Button className={styles.button} variant="primary" type="submit">
              Reset Password
            </Button>
            <div className={styles.goBack} onClick={() => history.push('/login')}>
              <FaChevronLeft />
              <span>go back</span>
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  )
}

export default connect(
  null,
  mapDispatchToProps
)(ResetPassword)