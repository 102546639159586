import { useState, useEffect } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { chain, first, map, isEmpty, find } from 'lodash'
import { Form } from 'react-bootstrap'
import { useAsync } from 'react-async'
import { actions as notificationActions } from '../../../redux/notification'
import { connect } from 'react-redux'

import { deleteBookingsUser, createBookingAdmin, getAllBookings, getAvailabilityAdmin, getUsers, getResourcesAdmin, createOverride, getOverrides, deleteOverride } from '../../../lib/api'
import BookingDetailModal from './booking-detail-modal'
import BookingCreateModal from './booking-create-modal'
import ConstrainCreateModal from './constraint-create-modal'
import OverrideDeleteModal from './override-delete-modal'

import styles from './index.module.scss'
import './calendar-styles.css'

const bookingsFetcher = ([start, end]) => getAllBookings(start, end)
const availabilityFetcher = ([ resourceID, from, to]) => getAvailabilityAdmin([resourceID], from, to).then(availability => availability[resourceID]?.availableBookings ?? [])
const userFetcher = () => getUsers()
const resourceFetcher = () => getResourcesAdmin()
const overrideFetcher = () => getOverrides()

function mapDispatchToProps (dispatch) {
  return {
    addNotification: (text, options) => dispatch(notificationActions.addNotification(text, options)),
  }
}

function AdminCalendar ({ addNotification }) {
  moment.locale('be', {
    week: {
      dow: 1,
      doy: 1,
    },
  })
  const localizer = momentLocalizer(moment)

  // TODO only fetch bookings for current date range
  const { data: bookings = [], run: getBookings } = useAsync({
    deferFn: bookingsFetcher
  })
  
  const { data: availability = [], run: getAvailability, reload: reloadAvailability } = useAsync({
    deferFn: availabilityFetcher
  })

  const { data: resources = [], run: getResourcesAdminData } = useAsync({
    deferFn: resourceFetcher
  })

  const { data: users = [], run: getUserData } = useAsync({
    deferFn: userFetcher
  })

  const { data: overrides = [], run: getOverrides, reload: reloadOverrides } = useAsync({
    deferFn: overrideFetcher
  })

  const [calendarDate, setCalendarDate] = useState(new Date())
  const [calendarView, setCalendarView] = useState('week')
  const [calendarEvents, setCalendarEvents] = useState([])

  const [selectedResource, setSelectedResource] = useState(undefined)

  const [selectedEvent, setSelectedEvent] = useState(undefined)
  const [openConstraintModal, setOpenConstraintModal] = useState(false)
  const [constraintStart, setConstraintStart] = useState(0)
  const [constraintEnd, setConstraintEnd] = useState(0)

  const handleModalClose = () => setSelectedEvent(undefined)

  useEffect(() => {
    getBookings()
    getResourcesAdminData()
    getUserData()
    getOverrides()
  }, [getBookings, getResourcesAdminData, getUserData, getOverrides])

  // get availability based on range
  useEffect(() => {
    if (!selectedResource || !calendarDate || !calendarView) return
    const from = moment(calendarDate).startOf(calendarView).valueOf()
    const to = moment(calendarDate).endOf(calendarView).valueOf()
    getAvailability(selectedResource.resourceID, from, to)
    getBookings(from, to)
  }, [getAvailability, selectedResource, calendarDate, calendarView, getBookings])

  // set the initial selected resource
  useEffect(() => {
    if (selectedResource || isEmpty(resources)) return
    setSelectedResource(resources[0])
  }, [resources, setSelectedResource, selectedResource])

  // create events
  useEffect(() => {
    if (!selectedResource) return

    let events = []

    if (!isEmpty(bookings)) {
      events = [ ...events, ...createBookingEvents(bookings, selectedResource)]
    }

    if (!isEmpty(availability)) {
      events = [ ...events, ...createAvailabilityEvents(availability)]
    }

    if (!isEmpty(overrides)) {
      events = [ ...events, ...createOverrideEvents(overrides)]
    }

    setCalendarEvents(events)
  }, [selectedResource, bookings, availability, overrides])

  const deleteBooking = () => {
    const { bookingID } = selectedEvent
  
    deleteBookingsUser([ bookingID ])
      .then(() => {
        addNotification('booking deleted')
        getBookings()
        handleModalClose()
        setCalendarDate(new Date(selectedEvent.start))
        setCalendarView('day')
        setSelectedEvent(undefined)
      })
  }

  const createBooking = (userID) => {
    const { start, end, resourceID } = selectedEvent

    createBookingAdmin([{
      start: moment(start).valueOf(),
      end: moment(end).valueOf(),
      userID,
      resourceID,
      status: 'confirmed'
    }])
      .then(() => {
        addNotification('booking created')
        getBookings()
        handleModalClose()
        setCalendarDate(new Date(start))
        setCalendarView('day')
      })
  }

  const handleCreateConstraint = ({ start, end }) => {
    if (!selectedResource) return
    setOpenConstraintModal(true)
    setConstraintStart(start)
    setConstraintEnd(end)
  }

  const createConstraint = () => {
    setOpenConstraintModal(false)
    createOverride(moment(constraintStart).valueOf(), moment(constraintEnd).valueOf(), selectedResource.resourceID)
      .then(() => {
        reloadOverrides()
        reloadAvailability()
      })
      .catch(err => console.error(err))
  }

  const handleDeleteOverride = () => {
    handleModalClose()
    deleteOverride(selectedEvent.constraintOverrideID)
      .then(() => {
        reloadOverrides()
        reloadAvailability()
      })
      .catch(err => console.error(err))
  }

  const handleResourceSelectChange = (resourceID) => {
    const resource = resources.find(resource => resource.resourceID === parseInt(resourceID))
    setSelectedResource(resource)
  }

  // Color days in month view which to grey to indicate that the resource is not available at that time
  const dayStyleProps = (props) => {
    if (!selectedResource) return
    const dayStart = moment(props).startOf('day').valueOf()
    const dayEnd = moment(props).endOf('day').valueOf()

    const hasAvailableSlot = find(availability, (availableSlot) => {
      const { start, end } = availableSlot
      return start >= dayStart && end <= dayEnd
    })

    const hasBooking = find(bookings, (booking) => {
      const { start, end } = booking
      return start >= dayStart && end <= dayEnd
    })

    if (hasAvailableSlot || hasBooking) return

    return {
      style: {
        backgroundColor: '#e6e6e6'
      }
    }
  }

  // Color days in month view which to grey to indicate that the resource is not available at that time
  const eventStyleProps = ({ type }) => {
    switch (type) {
      case 'booking': return {
        style: {
          backgroundColor: 'var(--main-color)'
        }
      }
      case 'closed': return {
        style: {
          backgroundColor: 'gray'
        }
      }
      default: return {
        style: {}
      }
    }
  }

  const minDate = new Date(moment().set('h', 8).set('m', 0))
  const maxDate = new Date(moment().set('h', 20).set('m', 0))
  
  // try to figure out interval minutes based on the availability constraint
  // default to 20
  let interval = 20

  if (!isEmpty(resources)) {
    const constraint = first(resources).availabilityConstraints
    if (!isEmpty(constraint)) {
      interval = first(constraint).intervalMinutes
    }
  }

  return (
    <div className={styles.container}>
      <h2>Overview</h2>
      <ResourceSelect resources={resources} selectedResource={selectedResource} handleChange={handleResourceSelectChange} />
      <div style={{ overflow: 'auto' }}>
        <Calendar
          date={calendarDate}
          onNavigate={setCalendarDate}

          view={calendarView}
          onView={(view) => setCalendarView(view)}
          views={['week', 'day']}

          localizer={localizer}
          events={calendarEvents}
          // interval minutes
          step={interval}
          // slot per interval
          timeslots={1}
          min={minDate}
          max={maxDate}
          onSelectEvent={setSelectedEvent}
          onSelectSlot={handleCreateConstraint}
          selectable
          dayPropGetter={dayStyleProps}
          eventPropGetter={eventStyleProps}
        />
      </div>
      <ModalSwitch
        selectedEvent={selectedEvent}
        handleClose={handleModalClose}
        deleteBooking={deleteBooking}
        createBooking={createBooking}
        deleteOverride={handleDeleteOverride}
        users={users}
      />
      <ConstrainCreateModal
        show={openConstraintModal}
        start={constraintStart}
        end={constraintEnd}
        handleClose={() => setOpenConstraintModal(false)}
        saveOverride={createConstraint}
      />
    </div>
  )
}

const ModalSwitch = ({ selectedEvent = {}, handleClose, deleteBooking, createBooking, deleteOverride, users }) => {
  const { type } = selectedEvent

  switch (type) {
    case 'booking': {
      return <BookingDetailModal
        handleClose={handleClose}
        event={selectedEvent}
        deleteBooking={deleteBooking}
      />
    }
    case 'available': {
      return <BookingCreateModal
        handleClose={handleClose}
        createBooking={createBooking}
        event={selectedEvent}
        users={users}
      />
    }
    case 'closed': {
      return <OverrideDeleteModal 
        handleClose={handleClose}
        event={selectedEvent}
        deleteOverride={deleteOverride}
      />
    }
    default: {
      return <></>
    }
  }
}

const ResourceSelect = ({ selectedResource, resources, handleChange }) => {
  if (!selectedResource) return null

  return (
    <div className={styles.resourceSelection}>
      <span>Select resource</span>
      <Form.Control className={styles.select} as="select" custom value={selectedResource.resourceID} onChange={(e) => handleChange(e.target.value)}>
        {
          map(resources, resource => {
            const { resourceID, name } = resource
            return (
              <option key={resourceID} value={resource.resourceID}>
                {name}
              </option>
            )
          })
        }
      </Form.Control>
    </div>
  )
}

function createBookingEvents (bookings, resource) {
  return chain(bookings)
    .filter(booking => booking.resourceID === resource.resourceID)
    .sortBy(['start'])
    .map((booking, index) => {
      const { firstname, lastname, start, end, bookingID } = booking
      return {
        id: `${bookingID}-${index}`,
        title: `${firstname} ${lastname}`,
        allDay: false,
        start: new Date(start),
        end: new Date(end),
        type: 'booking',
        bookingID
      }
    })
    .value()
}

function createAvailabilityEvents (availability) {
  return map(availability, (availability, index) => {
    const { resourceID, start, end } = availability
    return {
      id: `${resourceID}-${index}`,
      title: 'free slot',
      allDay: false,
      start: new Date(start),
      end: new Date(end),
      type: 'available',
      resourceID
    }
  })
}

function createOverrideEvents (overrides) {
  return map(overrides, (override, index) => {
    const { resourceID, start, end, constraintOverrideID } = override
    return {
      id: `${resourceID}-${index}`,
      title: 'override - closed',
      allDay: false,
      start: new Date(start),
      end: new Date(end),
      type: 'closed',
      resourceID,
      constraintOverrideID
    }
  })
}

export default connect(
  null,
  mapDispatchToProps
)(AdminCalendar)