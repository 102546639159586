import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'

import { organisationInfo } from '../lib/api'

const getInfo = createAsyncThunk(
  'organisation/info',
  async () => {
    const response = await organisationInfo()
    return response
  }
)

// Then, handle actions in your reducers:
const slice = createSlice({
  name: 'organisation',
  initialState: {
    data: {},
    loading: false,
    error: ''
  },
  reducers: {},
  extraReducers: {
    [getInfo.fulfilled]: (state, action) => {
      state.data = action.payload
      state.loading = false
    },
    [getInfo.pending]: (state, action) => {
      state.loading = true
    },
    [getInfo.rejected]: (state, action) => {
      state.error = get(action, 'payload.response.data', 'something went wrong')
      state.loading = false
    },
  }
})

export const { reducer } = slice

export const actions = {
  getInfo
}