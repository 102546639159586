import { useEffect, useState, useReducer } from 'react'
import { Form, Col, InputGroup } from 'react-bootstrap'
import { AiOutlineSearch } from 'react-icons/ai'
import { filter } from 'lodash'
import { useAsync } from 'react-async'
import { connect } from 'react-redux'
import { isEmpty, omit } from 'lodash'

import UserTable from './user-table'

import { actions as notificationActions } from '../../../redux/notification'

import styles from './index.module.scss'
import Loader from '../../../components/loader'

import { 
  getUsers,
  getRoles,
  deleteUser,
  updateUser,
  resendActivation
} from '../../../lib/api'

const userFetcher = () => getUsers()
const rolesFetcher = () => getRoles()

function mapDispatchToProps (dispatch) {
  return {
    addNotification: (text, options) => dispatch(notificationActions.addNotification(text, options)),
  }
}

function AdminUsers ({ addNotification }) {
  const [usersData, setUsersData] = useState([])
  const [selectedUser, setSelectedUser] = useState(0)
  const [search, setSearch] = useState('')
  const forceUpdate = useReducer(() => ({}))[1]

  const { data: users = [], isPending: usersLoading, run: getUserData } = useAsync({
    deferFn: userFetcher
  })

  const { data: roles = [], isPending: rolesLoading, run: getRoleData } = useAsync({
    deferFn: rolesFetcher
  })

  const deleteUserHandler = async (userID) => {
    await deleteUser(userID)
    addNotification('user deleted')
    getUserData()
  }

  const updateUserHandler = async (user) => {
    await updateUser(user.userID, omit(user, [
      'organisationID',
      'userID',
      'email',
      'corporation',
      'isVerified',
      'roleExpires',
      'isAdmin'
    ]))
    addNotification('user edited')
    forceUpdate()
  }

  const ticketAddHandler = (userID, amount) => {
    const user = users.find(u => u.userID === userID)
    user.tickets = user.tickets + amount
    setUsersData(filterUsers(users))
    updateUserHandler(user)
  }

  const ticketRemoveHandler = (userID, amount) => {
    const user = users.find(u => u.userID === userID)
    user.tickets = user.tickets - amount
    setUsersData(filterUsers(users))
    updateUserHandler(user)
  }

  const setRoleHandler = (userID, roleID) => {
    const user = users.find(u => u.userID === userID)
    user.roleID = roleID
    setUsersData(filterUsers(users))
    updateUserHandler(user)
  }
  
  const resendActivationHandler = (userID) => {
    resendActivation(userID)
  }

  useEffect(() => {
    getUserData()
    getRoleData()
  }, [
    getUserData,
    getRoleData,
  ])

  useEffect(() => {
    if (isEmpty(users)) return
    setUsersData([...users])
  }, [users])

  const loading =
    usersLoading ||
    rolesLoading

  if (loading) return <Loader />

  const filterUsers = (users) => {
    const filteredUsers = filter(users, user => {
      const { firstname, lastname, email } = user

      if (firstname.toLowerCase().includes(search) || lastname.toLowerCase().includes(search) || email.toLowerCase().includes(search)) {
        return user
      }
    })

    return filteredUsers
  }

  const handleUserSearch = (e) => {
    const search = e.target.value

    if (search === '') {
      setSearch('')
      return getUserData()
    }

    setSearch(search)
    setUsersData(filterUsers(users))
  }

  return (
    <div className={styles.container}>
      <div className={styles.containerHead}>
        <h2>Users</h2>
      </div>
      <Form className={styles.searchbar}>
        <Form.Row className="align-items-center">
          <Col xs="auto">
            <InputGroup className="mb-2">
              <InputGroup.Prepend>
                <InputGroup.Text><AiOutlineSearch /></InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                id="inlineFormInput"
                placeholder="Search users"
                onChange={handleUserSearch}
              />
            </InputGroup>
          </Col>
        </Form.Row>
      </Form>
      <UserTable
        users={usersData}
        roles={roles}
        ticketAdd={ticketAddHandler}
        ticketRemove={ticketRemoveHandler}
        deleteUser={deleteUserHandler}
        selectedUser={selectedUser}
        setSelected={u => setSelectedUser(u)}
        changeUserRole={setRoleHandler}
        resendActivation={resendActivationHandler}
      />
    </div>
  )  
}

export default connect(
  null,
  mapDispatchToProps
)(AdminUsers)
