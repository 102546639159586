import { Form, Button, Row, Col } from "react-bootstrap"
import { connect } from "react-redux"
import { useFormik } from "formik"
import { pick } from "lodash"
import { updateProfile } from "../../lib/api"
import { actions as notificationActions } from "../../redux/notification"
import { actions as userActions } from "../../redux/user"
import Banner from "../../components/banner"

import styles from "./index.module.scss"

const userWhitelist = [
  "firstname",
  "lastname",
  "street",
  "streetNumber",
  "municipality",
  "postalCode",
  "telephone",
  "birthdate",
]

function mapStateToProps(state) {
  return {
    user: state.user.data || {},
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addNotification: (text, options) =>
      dispatch(notificationActions.addNotification(text, options)),
    getProfile: () => dispatch(userActions.getProfile()),
  }
}

function Profile({ user, addNotification, getProfile }) {
  const formik = useFormik({
    initialValues: {
      ...user,
    },
    validate,
    onSubmit: (values) => {
      const newUser = pick(values, userWhitelist)
      console.log(newUser)
      updateProfile(newUser)
        .then(() => {
          addNotification("Successfully updated profile!")
          getProfile()
        })
        .catch((err) => addNotification("Something went wrong."))
    },
  })

  return (
    <>
      <Banner />
      <div className={styles.container}>
        <h3>Profile</h3>
        <h2>Personal information</h2>

        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Label className={styles.formLabel}>Name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  required
                  name="firstname"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.firstname}
                  className={styles.formText}
                />
                {formik.touched.firstname && formik.errors.firstname ? (
                  <div className={styles.errorField}>
                    {formik.errors.firstname}
                  </div>
                ) : null}
              </Col>

              <Col>
                <Form.Label className={styles.formLabel}>Last name</Form.Label>
                <Form.Control
                  placeholder="Name"
                  required
                  name="lastname"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.lastname}
                  className={styles.formText}
                />
                {formik.touched.lastname && formik.errors.lastname ? (
                  <div className={styles.errorField}>
                    {formik.errors.lastname}
                  </div>
                ) : null}
              </Col>
            </Row>
          </Form.Group>

          <Form.Group>
            <Form.Label className={styles.formLabel}>Email</Form.Label>
            <Form.Control
              placeholder="Email"
              required
              name="email"
              disabled
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              className={styles.formText}
            />
            <Form.Text className="text-muted">This cannot be changed</Form.Text>
            {formik.touched.email && formik.errors.email ? (
              <div className={styles.errorField}>{formik.errors.email}</div>
            ) : null}
          </Form.Group>

          <Form.Group>
            <Row>
              <Col xs={12} sm={3}>
                <Form.Label className={styles.formLabel}>Street</Form.Label>

                <Form.Control
                  placeholder="Street"
                  required
                  name="street"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.street}
                  className={styles.formText}
                />
                {formik.touched.street && formik.errors.street ? (
                  <div className={styles.errorField}>
                    {formik.errors.street}
                  </div>
                ) : null}
              </Col>

              <Col xs={12} sm={3}>
                <Form.Label className={styles.formLabel}>
                  Street number
                </Form.Label>

                <Form.Control
                  placeholder="Number"
                  required
                  name="streetNumber"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.streetNumber}
                  className={styles.formText}
                />
                {formik.touched.streetNumber && formik.errors.streetNumber ? (
                  <div className={styles.errorField}>
                    {formik.errors.streetNumber}
                  </div>
                ) : null}
              </Col>

              <Col xs={12} sm={3}>
                <Form.Label className={styles.formLabel}>City</Form.Label>
                <Form.Control
                  placeholder="City"
                  required
                  name="municipality"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.municipality}
                  className={styles.formText}
                />
                {formik.touched.municipality && formik.errors.municipality ? (
                  <div className={styles.errorField}>
                    {formik.errors.municipality}
                  </div>
                ) : null}
              </Col>

              <Col xs={12} sm={3}>
                <Form.Label className={styles.formLabel}>
                  Postal code
                </Form.Label>
                <Form.Control
                  placeholder="Postal Code"
                  required
                  name="postalCode"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.postalCode}
                  className={styles.formText}
                />
                {formik.touched.postalCode && formik.errors.postalCode ? (
                  <div className={styles.errorField}>
                    {formik.errors.postalCode}
                  </div>
                ) : null}
              </Col>
            </Row>
          </Form.Group>

          <Form.Group>
            <Form.Label className={styles.formLabel}>Mobile phone</Form.Label>
            <Form.Control
              placeholder="Telephone"
              required
              name="telephone"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.telephone}
              className={styles.formText}
            />
            {formik.touched.telephone && formik.errors.telephone ? (
              <div className={styles.errorField}>{formik.errors.telephone}</div>
            ) : null}
          </Form.Group>

          <Form.Group>
            <Form.Label className={styles.formLabel}>Birthdate</Form.Label>
            <Form.Control
              className={styles.formText}
              placeholder="Birthdate"
              required
              name="birthdate"
              type="date"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={
                new Date(formik.values.birthdate).toISOString().split("T")[0]
              }
            />
            {formik.touched.birthdate && formik.errors.birthdate ? (
              <div className={styles.errorField}>{formik.errors.birthdate}</div>
            ) : null}
          </Form.Group>

          <Button type="submit" className={styles.button}>
            Update profile
          </Button>
        </Form>
      </div>
    </>
  )
}

const validate = (values) => {
  const errors = {}

  if (!values.firstname) {
    errors.firstname = "Required"
  }

  if (!values.lastname) {
    errors.lastname = "Required"
  }

  if (!values.email) {
    errors.email = "Required"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address"
  }

  if (!values.street) {
    errors.street = "Required"
  }

  if (!values.streetNumber) {
    errors.streetNumber = "Required"
  }

  if (!values.municipality) {
    errors.municipality = "Required"
  }

  if (!values.postalCode) {
    errors.postalCode = "Required"
  }

  if (!values.telephone) {
    errors.telephone = "Required"
  }

  if (!values.birthdate) {
    errors.birthdate = "Required"
  }

  return errors
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
