import { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import ConstraintsTable from "./constraintsTable"
import CreateConstraints from './createConstraints'

import styles from './newCalendar.module.scss'

const EditCalendarModal = ({ show, handleClose, selectedCalendar, updateCalendar }) => {
  const [constraints, setConstraints] = useState(selectedCalendar.constraints)

  const handleCreateConstraint = (newConstraint) => {
    setConstraints([ ...constraints, newConstraint ])
  }

  const handleDeleteConstraint = (index) => {
    const copy = [...constraints]
    copy.splice(index, 1)
    setConstraints(copy)
  }

  const handleUpdateCalendar = () => {
    updateCalendar({ ...selectedCalendar, constraints })
  }

  return (
    <>
      <Modal 
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered 
        show={show} 
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit openinghours {selectedCalendar.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
          <ConstraintsTable constraints={constraints} deleteConstraint={handleDeleteConstraint} />
          <div>
            <h4>Add more openinghours</h4>
            <CreateConstraints createConstraint={handleCreateConstraint}/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className={styles.savebtn} onClick={() => handleUpdateCalendar()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditCalendarModal