import { Button, Form } from "react-bootstrap"
import format from "date-fns/format"
import { map } from "lodash"
import { useFormik } from 'formik'
import moment from 'moment'

import styles from './createConstraints.module.scss'

moment.locale('en')

const CreateConstraints = ({ createConstraint }) => {
  const formik = useFormik({
    initialValues: {
      day: 'monday',
      endDate: format(new Date(), 'yyyy-MM-dd'),
      startDate: format(new Date(), 'yyyy-MM-dd'),
      start: '10:00',
      end: '18:00',
      intervalMinutes: 20
    },
    validate,
    onSubmit: values => {
      values.startDate = format(new Date(values.startDate), 'yyyy-MM-dd')
      values.endDate = format(new Date(values.endDate), 'yyyy-MM-dd')
      createConstraint(values)
    },
  })

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>

        <div style={{ display: 'flex' }}>
          <Form.Group className={styles.availabilityFormGroup} controlId='day'>
            <Form.Label className={styles.formLabel}>Select a day</Form.Label>
            <Form.Control
              className={styles.select}
              as="select"
              custom
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.day}
            >
              {
                map(weekdays, day => {
                  return (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  )
                })
              }
            </Form.Control>
          </Form.Group>
          <Form.Group className={styles.availabilityFormGroup} controlId={`startDate`}>
            <Form.Label className={styles.formLabel}>Start date</Form.Label>
            <Form.Control
              type="date"
              placeholder="Start"
              name='startDate'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.startDate}
            />
            {formik.touched.startDate && formik.errors.startDate ? (
              <div className={styles.errorField}>{formik.errors.startDate}</div>
            ) : null}
          </Form.Group>
          <Form.Group className={styles.availabilityFormGroup} controlId={`endDate`}>
            <Form.Label className={styles.formLabel}>End date</Form.Label>
            <Form.Control
              type="date"
              placeholder="End"
              name='endDate'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.endDate}
            />
            {formik.touched.endDate && formik.errors.endDate ? (
              <div className={styles.errorField}>{formik.errors.endDate}</div>
            ) : null}
          </Form.Group>
        </div>
        <div style={{ display: 'flex' }}>
          <Form.Group className={styles.availabilityFormGroup} controlId={`start`}>
            <span>Start hour</span>
            <Form.Control
              type="text"
              placeholder="Start"
              name='start'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.start}
            />
            {formik.touched.start && formik.errors.start ? (
              <div className={styles.errorField}>{formik.errors.start}</div>
            ) : null}
          </Form.Group>
          <Form.Group className={styles.availabilityFormGroup} controlId={`end`}>
            <span>End hour</span>
            <Form.Control
              type="text"
              placeholder="End"
              name='end'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.end}
            />
            {formik.touched.end && formik.errors.end ? (
              <div className={styles.errorField}>{formik.errors.end}</div>
            ) : null}
          </Form.Group>
          <Form.Group className={styles.availabilityFormGroup} controlId={`intervalMinutes`}>
            <span>Booking slot duration (minutes)</span>
            <Form.Control
              type="number"
              placeholder="Duration"
              name='intervalMinutes'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.intervalMinutes}
            />
            {formik.touched.intervalMinutes && formik.errors.intervalMinutes ? (
              <div className={styles.errorField}>{formik.errors.intervalMinutes}</div>
            ) : null}
          </Form.Group>
        </div>
        <div>
          <Button className={styles.savebtn} type='submit'>
            Add timeslot
          </Button>
        </div>
      </Form>
    </>
  )
}

const validate = values => {
  const errors = {}

  if (!values.day) {
    errors.day = 'Required'
  }

  if (!values.startDate) {
    errors.startDate = 'required'
  }
  // if (new Date(values.startDate) < new Date()) {
    // errors.startDate = 'Date lies in the past'
  // }

  if (!values.start) {
    errors.start = 'required'
  } else if (!/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(values.start)) {
    errors.start = 'Start must be in format 00:00 - 24:00'
  }

  if (!values.end) {
    errors.end = 'required'
  } else if (!/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(values.end)) {
    errors.end = 'Start must be in format 00:00 - 24:00'
  }

  if (!values.intervalMinutes) {
    errors.intervalMinutes = 'required'
  } else if (values.intervalMinutes <= 0 || values.intervalMinutes > 60) {
    errors.intervalMinutes = 'Interval must be between 1 and 60 minutes'
  }

  if (!values.start) {
    errors.start = 'required'
  }
  if (!values.end) {
    errors.end = 'required'
  } else if (values.start.split(':') > values.end.split(':')) {
    errors.start = 'Start time cannot be past end time'
  }

  return errors
}

const weekdays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]

export default CreateConstraints
