import { Modal, Button } from 'react-bootstrap'

function ConfirmDeleteModal({ show, handleClose, handleDelete }) {
  return (
    <>
      <Modal 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered 
        show={show} 
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Refund reservation?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel this reservation? Your ticket will be refunded in that case.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Delete Booking
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ConfirmDeleteModal