import Loader from '../../components/loader'
import { Button, Card, Row, Col } from 'react-bootstrap'
import { IoTicketOutline } from "react-icons/io5"
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import ResourceCarousel from '../../components/resource-carousel'

import styles from './list.module.scss'

function TicketList ({ resources, loading, user }) {
  const history = useHistory()

  if (loading) return <Loader />

  return (
    <div className={styles.container}>
      <h3>Purchased tickets</h3>
      <h2 className={styles.blackTitle}>My Tickets</h2>
      <TicketCards
        history={history}
        resources={resources}
        user={user}
      />
    </div>
  )  
}

function TicketCards ({ history, resources, user }) {
  return (
    <Row className={styles.mainRow}>
      <Col xs={12} lg={4} className={styles.ticketCardColumn}>
        <TicketCard
          resources={resources}
          user={user}
        />
      </Col>

      <Col xs={12} lg={4} className={styles.ticketCardColumn}>
        <Card className={styles.infoCard}>
          <Card.Body>
            <IoTicketOutline />
            <h2>Need more tickets?</h2>
            <img alt='waves' src='/mustache.svg'/>
            <br/>
            <span>Buy your tickets now with our new system. Compare prices and much more!</span>
            <Button
              onClick={() => history.push('/tickets/buy')}
            >
              <span>Buy Tickets</span>
            </Button>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}

const TicketCard = ({ resources, user }) => {
  return (
    <Card className={styles.card}>
      <div className={styles.cardImageContainer}>
        <ResourceCarousel resources={resources}/>
      </div>
      <Card.Body>
        <div className={styles.cardRow}>
          <div className={styles.upperLeftCardRow}>
            <span>tickets</span>
          </div>
          
          <div className={styles.upperRightCardRow}>
            <span>{user.tickets}</span>
          </div>
        </div>
        <div className={styles.cardFooter}>
          <span>You have {user.tickets} tickets available</span>
        </div>
      </Card.Body>
    </Card>
  )
}

export default connect(
  null,
  null,
)(TicketList)
