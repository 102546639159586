import { useEffect } from 'react'
import styles from './index.module.scss'
import { useAsync } from 'react-async'
import { connect } from 'react-redux'
import Loader from '../../../components/loader'

import { actions as notificationActions } from '../../../redux/notification'
import { actions as organisationActions } from '../../../redux/organisation'

import OrganisationSettings from './orgSettings'
import BrandingSettings from './brandingSettings'

import { 
  organisationBranding,
  organisationGet,
  updateOrganisation,
  updateOrganisationBranding
} from '../../../lib/api'

const organisationBrandingFetcher = () => organisationBranding()
const organisationFetcher = () => organisationGet()

function mapDispatchToProps (dispatch) {
  return {
    addNotification: (text, options) => dispatch(notificationActions.addNotification(text, options)),
    getOrg: () => dispatch(organisationActions.getInfo())
  }
}

function Settings({ addNotification }) {
  const { data: branding = [], isPending: brandingLoading, run: getBranding } = useAsync({
    deferFn: organisationBrandingFetcher
  })

  const { data: organisation = {}, isPending: organisationLoading, run: getOrganisationData } = useAsync({
    deferFn: organisationFetcher
  })

  useEffect(() => {
    getBranding()
    getOrganisationData()
  }, [
    getBranding,
    getOrganisationData,
  ])

  const updateOrganisationHandler = async (organisation) => {
    await updateOrganisation(organisation)
    addNotification('organisation settings saved')
    await getOrganisationData()
  }

  const updateOrganisationBrandingHandler = async (branding) => {
    await updateOrganisationBranding(branding)
    addNotification('organisation settings saved')
    await getBranding()
  }

  const loading =
    brandingLoading || 
    organisationLoading
  
  if (loading) return <Loader />

  return (
    <div className={styles.container}>
      <h2>Settings {organisation.name}</h2>

      <OrganisationSettings organisation={organisation} updateOrganisation={updateOrganisationHandler}/>
      <BrandingSettings branding={branding} updateOrganisationBranding={updateOrganisationBrandingHandler} />
    </div>
  )
}

export default connect(
  null,
  mapDispatchToProps
)(Settings)